"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "card-header" };
const _hoisted_2 = { class: "nav nav-tabs card-header-tabs" };
const _hoisted_3 = ["aria-current", "onClick"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, null, [
        (0, vue_1.createElementVNode)("div", _hoisted_1, [
            (0, vue_1.createElementVNode)("ul", _hoisted_2, [
                ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.tabInstances, (instance, index) => {
                    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", {
                        class: "nav-item",
                        key: index
                    }, [
                        (0, vue_1.createElementVNode)("a", {
                            class: (0, vue_1.normalizeClass)(["nav-link", _ctx.currentTab === instance.tabId ? ['active'] : []]),
                            "aria-current": _ctx.currentTab === instance.tabId,
                            href: "#",
                            onClick: (0, vue_1.withModifiers)(($event) => (_ctx.currentTab = instance.tabId), ["prevent"])
                        }, (0, vue_1.toDisplayString)(instance.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)
                    ]));
                }), 128 /* KEYED_FRAGMENT */))
            ])
        ]),
        (0, vue_1.renderSlot)(_ctx.$slots, "default")
    ], 64 /* STABLE_FRAGMENT */));
}
exports.render = render;
