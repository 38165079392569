"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data() {
        return {
            currentTab: 0,
            tabInstances: [],
            nextTabId: 0,
        };
    },
    provide() {
        return {
            tabController: {
                addTab: this.addTab.bind(this),
                isActive: this.isActive.bind(this),
                removeTab: this.removeTab.bind(this),
            },
        };
    },
    methods: {
        addTab(title, enabled) {
            const tabId = this.nextTabId++;
            this.tabInstances.push({
                tabId,
                title,
                enabled,
            });
            return tabId;
        },
        isActive(tabId) {
            return this.currentTab === tabId;
        },
        removeTab(tabId) {
            this.tabInstances = this.tabInstances.filter((x) => x.tabId !== tabId);
            if (this.currentTab === tabId) {
                this.currentTab = this.tabInstances.length
                    ? this.tabInstances[0].tabId
                    : 0;
            }
        },
    },
});
