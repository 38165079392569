"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    data() {
        return {
            tabId: -1,
        };
    },
    props: {
        title: String,
    },
    setup() {
        const tabController = (0, vue_1.inject)("tabController");
        if (!tabController) {
            throw new Error("Cannot find tab controller");
        }
        return { tabController };
    },
    created() {
        this.tabId = this.tabController.addTab(this.title || "Tab", true);
    },
    unmounted() {
        this.tabController.removeTab(this.tabId);
        this.tabId = -1;
    },
});
