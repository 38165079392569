"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateManager = exports.STATE_MANAGER = exports.toFragment = exports.fromFragment = void 0;
const vue_1 = require("vue");
const environment_1 = require("./environment");
const parser_1 = require("./parser");
const urlstorage_1 = require("./urlstorage");
function fromFragment(fragment) {
    let version = "0";
    if (fragment.match(/[0-9A-Za-z]@/)) {
        version = fragment.substr(0, 1);
        fragment = fragment.substr(2);
    }
    if (version === "0") {
        // Simple string or state object.
        try {
            if (!fragment)
                return { template: "", environment: new environment_1.EnvironmentImpl() };
            let result = (0, urlstorage_1.decode)(fragment);
            if (typeof result === "string") {
                return { template: result, environment: new environment_1.EnvironmentImpl() };
            }
            else if (isObject(result)) {
                let template = "";
                let environment;
                if (typeof result["template"] === "string") {
                    template = result["template"];
                }
                if (isObject(result["environment"])) {
                    environment = environment_1.EnvironmentImpl.fromObject(result["environment"]);
                }
                else {
                    environment = new environment_1.EnvironmentImpl();
                }
                return { template, environment };
            }
        }
        catch (e) {
            throw new Error(`Invalid fragment: ${e}`);
        }
        throw new Error("Invalid fragment contents");
    }
    else {
        throw new Error(`Invalid fragment version: ${version}`);
    }
}
exports.fromFragment = fromFragment;
/**
 * Exports a template and environment to a fragment.
 *
 * Does not prepend a version, on the assumption that we never break backwards
 * compatibility. Would otherwise prepend "0@".
 */
function toFragment(template, environment) {
    if (environment.isEmpty()) {
        if (template.length === 0) {
            return "";
        }
        // Nothing in the environment. Cut it out entirely.
        return (0, urlstorage_1.encode)(template);
    }
    else {
        let environmentObject = environment.toObject();
        return (0, urlstorage_1.encode)({ template, environment: environmentObject });
    }
}
exports.toFragment = toFragment;
function isObject(object) {
    return typeof object === "object";
}
exports.STATE_MANAGER = Symbol("stateManager");
class StateManager {
    constructor() {
        this.state = (0, vue_1.reactive)({
            template: "",
            environment: new environment_1.EnvironmentImpl(),
            ast: null,
            parseTree: "",
            annotations: [],
            errors: undefined,
            cursor: [-1, -1],
            isExpr: false,
        });
        this.settings = (0, vue_1.reactive)({
            debug: false,
            showAll: "false",
            useAce: true,
            version: __VERSION__,
        });
        (0, vue_1.watch)(() => this.state.template, (template) => {
            var _a;
            const state = this.state;
            state.parseTree = "";
            state.errors = undefined;
            state.annotations = [];
            if (!template) {
                state.ast = null;
                state.isExpr = false;
            }
            else {
                const parser = new parser_1.Parser();
                const [exprResult, exprErrors] = parser.tryParseExpression(template);
                if (!exprErrors) {
                    state.ast = exprResult || null;
                    state.parseTree = exprResult ? exprResult.debug() : "";
                    state.isExpr = true;
                }
                else {
                    const [result, errors] = parser.tryParse(state.template);
                    state.ast = result || null;
                    state.parseTree = result ? result.debug() : "";
                    state.errors = errors;
                    state.isExpr = false;
                }
                state.annotations = ((_a = state.ast) === null || _a === void 0 ? void 0 : _a.collectAnnotations()) || [];
            }
            state.environment.update(state.ast || undefined);
        });
        (0, vue_1.markRaw)(this);
    }
}
exports.StateManager = StateManager;
