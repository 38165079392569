"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEST_ONLY = exports.getClosestValue = exports.choicesAreEquivalent = exports.keyForString = exports.EnvironmentImpl = exports.EMPTY_ENVIRONMENT = void 0;
const html_entities_1 = require("html-entities");
const model_1 = require("./parser/model");
exports.EMPTY_ENVIRONMENT = Object.freeze({
    playerName: "",
    characterName: "",
    isEmpty() {
        return true;
    },
    getProperty: (name) => undefined,
    setProperty: (name, value) => {
        throw new Error("Cannot set property on empty environment");
    },
    getPropertyObject: (name) => undefined,
    getPropertyNames: () => [],
    toObject: () => ({}),
    update: (node) => { },
});
class EnvironmentImpl {
    constructor() {
        this.playerName = "";
        this.characterName = "";
        this.properties = new Map();
    }
    getProperty(name) {
        var _a;
        return (_a = this.properties.get(keyForString(name))) === null || _a === void 0 ? void 0 : _a.value;
    }
    setProperty(name, value) {
        if (this.properties.has(keyForString(name))) {
            this.properties.get(keyForString(name)).value = value;
        }
        else {
            this.properties.set(keyForString(name), {
                name,
                value,
                possibleValues: [],
            });
        }
    }
    getPropertyObject(name, create = false) {
        const key = keyForString(name);
        if (!this.properties.has(key)) {
            this.setProperty(name, "");
        }
        return this.properties.get(key);
    }
    setPropertyObject(name, property) {
        return this.properties.set(keyForString(name), property);
    }
    getPropertyNames() {
        return Array.from(this.properties.keys());
    }
    /** Merges detected nodes from the passed root. */
    update(root) {
        let keysAndNodes = [];
        if (root) {
            (0, model_1.visit)(root, (x) => {
                let maybeKey = keyFor(x);
                if (maybeKey)
                    keysAndNodes.push({ key: maybeKey, node: x });
            });
        }
        let keySet = new Set(keysAndNodes.map((x) => x.key));
        let snapshot = Array.from(this.properties.entries());
        for (let [key, value] of snapshot) {
            if (!keySet.has(keyForString(key)) && !value.value) {
                this.properties.delete(keyForString(key));
            }
        }
        for (let { key, node } of keysAndNodes) {
            if (!this.getPropertyObject(key))
                this.setProperty(key, "");
            if (node.type === "roll-query") {
                const children = node.childNodes();
                const propertyObject = this.getPropertyObject(key);
                if (children.length === 1) {
                    delete propertyObject.defaultValue;
                    propertyObject.possibleValues = [];
                }
                else if (children.length === 2) {
                    propertyObject.defaultValue = stringFor(children[1]);
                    propertyObject.possibleValues = [];
                }
                else if (children.length > 2) {
                    delete propertyObject.defaultValue;
                    propertyObject.possibleValues = possibleValuesFor(children);
                }
            }
        }
    }
    isEmpty() {
        return !this.playerName && !this.characterName && !this.properties.size;
    }
    static fromObject(object) {
        const newEnv = new EnvironmentImpl();
        if (typeof object["characterName"] === "string") {
            newEnv.characterName = object.characterName;
        }
        if (typeof object["playerName"] === "string") {
            newEnv.playerName = object.playerName;
        }
        if (typeof object["properties"] === "object") {
            for (let [key, value] of Object.entries(object["properties"])) {
                if (typeof value === "string") {
                    newEnv.setProperty(key, value);
                }
                else if (typeof value === "object") {
                    const envProperty = {
                        name: value.name,
                        value: value.value,
                        defaultValue: value.defaultValue,
                        id: value.id,
                        label: value.label,
                        possibleValues: Array.from(value.possibleValues).filter((x) => x.length === 2 &&
                            typeof x[0] === "string" &&
                            typeof x[1] === "string"),
                    };
                    newEnv.setPropertyObject(key, envProperty);
                }
            }
        }
        return newEnv;
    }
    toObject() {
        const returnObject = {};
        const { characterName, playerName, properties } = this;
        if (characterName)
            returnObject.characterName = characterName;
        if (playerName)
            returnObject.playerName = playerName;
        if (properties.size) {
            let propertiesObject = {};
            properties.forEach((value, key) => {
                if (value.value || value.id || value.label) {
                    propertiesObject[key] = value;
                }
            });
            returnObject.properties = propertiesObject;
        }
        return returnObject;
    }
}
exports.EnvironmentImpl = EnvironmentImpl;
/** Returns a string key corresponding to the node. */
function keyFor(node) {
    let groups = [];
    try {
        // TODO: Check case for all of these
        switch (node.type) {
            case "ability":
                let abilityNode = node;
                let children = abilityNode.children.length;
                if (children < 1 || children > 2)
                    return undefined;
                groups.push(stringFor(abilityNode.children[0]));
                if (children > 1)
                    groups.push(stringFor(abilityNode.children[1]));
                return `%{${groups.join("|")}}`;
            case "macro":
                let macroNode = node;
                if (macroNode.children.length !== 1)
                    return undefined;
                let macroName = stringFor(macroNode.children[0]);
                return `#${macroName}`;
            case "attribute-query":
                let attributeNode = node;
                switch (attributeNode.children.length) {
                    case 1:
                        // @{foo}: Only useful when the character is known.
                        groups.push(stringFor(attributeNode.children[0]));
                        break;
                    case 2:
                        // @{char|foo}: Standard attribute call.
                        groups.push(stringFor(attributeNode.children[0]));
                        groups.push(stringFor(attributeNode.children[1]));
                        break;
                    case 3:
                        // @{target|targetname|foo}: Accepts a target.
                        groups.push(stringFor(attributeNode.children[0]));
                        groups.push(stringFor(attributeNode.children[1]));
                        groups.push(stringFor(attributeNode.children[2]));
                        break;
                    case 4:
                        // @{target|targetname|foo|flags}: Can get the "max" etc.
                        groups.push(stringFor(attributeNode.children[0]));
                        groups.push(stringFor(attributeNode.children[1]));
                        groups.push(stringFor(attributeNode.children[2]));
                        groups.push(stringFor(attributeNode.children[3]));
                        break;
                    default:
                        return undefined;
                }
                return `@{${groups.join("|")}}`;
            case "roll-query":
                let rollQueryNode = node;
                if (rollQueryNode.children.length < 1)
                    return undefined;
                return `?{${stringFor(rollQueryNode.children[0])}}`;
        }
    }
    catch (e) { }
    return undefined;
}
const INLINE_ROLL_REGEX = /^\s*\?\{([^|]*)(.|\n)*\}\s*$/;
const ARBITRARY_PIPE_REGEX = /^\s*((@|%)\{)(.*)(\})\s*/;
function keyForString(input, toLowerCase = true) {
    const result = (function () {
        INLINE_ROLL_REGEX.lastIndex = 0;
        const inlineRollResult = INLINE_ROLL_REGEX.exec(input);
        if (inlineRollResult) {
            return `?{${inlineRollResult[1].trim()}}`;
        }
        ARBITRARY_PIPE_REGEX.lastIndex = 0;
        const arbitraryPipeResult = ARBITRARY_PIPE_REGEX.exec(input);
        if (arbitraryPipeResult) {
            return [
                arbitraryPipeResult[1],
                arbitraryPipeResult[3]
                    .split("|")
                    .map((x) => x.trim())
                    .join("|"),
                arbitraryPipeResult[4],
            ].join("");
        }
        return input.trim();
    })();
    if (toLowerCase)
        return result.toLowerCase();
    return result;
}
exports.keyForString = keyForString;
function stringFor(node) {
    if (node instanceof model_1.TextNode) {
        return node.value.trim();
    }
    else if (node instanceof model_1.CompoundNode) {
        let strings = [];
        for (let x of node.children) {
            if (x instanceof model_1.TextNode) {
                strings.push(x.value);
            }
            else
                throw new Error();
        }
        return strings.join("").trim();
    }
    else if (node instanceof model_1.KeyValueNode) {
        let strings = [];
        for (let x of node.key) {
            if (x instanceof model_1.TextNode) {
                strings.push(x.value);
            }
            else
                throw new Error();
        }
        return strings.join("").trim();
    }
    else {
        throw new Error();
    }
}
function possibleValuesFor(children) {
    const possibleValues = [];
    for (let i = 1; i < children.length; i++) {
        // skip the prompt
        if (children[i] instanceof model_1.KeyValueNode) {
            const child = children[i];
            const keySummary = (0, html_entities_1.decode)((0, model_1.summarize)(child.key).trim());
            const valueSummary = (0, html_entities_1.decode)((0, model_1.summarize)(child.value).trim());
            possibleValues.push([keySummary, valueSummary]);
        }
        else {
            const childSummary = (0, html_entities_1.decode)(children[i].summarize().trim());
            possibleValues.push([childSummary, childSummary]);
        }
    }
    return possibleValues;
}
/** Check that the array of choices are equivalent. TODO: check text. */
function choicesAreEquivalent(a, b) {
    return a.length === b.length;
}
exports.choicesAreEquivalent = choicesAreEquivalent;
/**
 * Gets the closest matching roll query choice, since the saved choices
 * are not evaluated with the full evaluator and might not have their
 * substitutions handled.
 *
 * TODO: Re-substitute to check even closer.
 */
function getClosestValue(choices, id, label, value) {
    for (let i = 0; i < choices.length; i++) {
        let [choiceKey, choiceValue] = choices[i];
        if (value === choiceValue)
            return { id: i, label: choiceKey, value: choiceValue };
    }
    for (let i = 0; i < choices.length; i++) {
        let [choiceKey, choiceValue] = choices[i];
        if (label === choiceKey)
            return { id: i, label: choiceKey, value: choiceValue };
    }
    if (typeof id === "number" && id >= 0 && id < choices.length) {
        const [choiceKey, choiceValue] = choices[id];
        return { id, label: choiceKey, value: choiceValue };
    }
    return undefined;
}
exports.getClosestValue = getClosestValue;
function propertiesOf(impl) {
    // escape private modifier for test
    return impl.properties;
}
exports.TEST_ONLY = { keyFor, propertiesOf };
