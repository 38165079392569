"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.simulateAll = exports.simulate = exports.Disposition = void 0;
const html_entities_1 = require("html-entities");
const environment_1 = require("./environment");
const evaluator_1 = require("./evaluator");
const parser_1 = require("./parser");
const model_1 = require("./parser/model");
const SIMULATION_STEP_KEYWORD_ARGS = {
    annotations: [],
    environmentProperties: Object.freeze([]),
    errorMessage: "",
    highlightRanges: Object.freeze([]),
    result: undefined,
};
var Disposition;
(function (Disposition) {
    Disposition[Disposition["EMPTY"] = 0] = "EMPTY";
    Disposition[Disposition["SIGNIFICANT"] = 1] = "SIGNIFICANT";
    Disposition[Disposition["WARNING"] = 2] = "WARNING";
    Disposition[Disposition["ERROR"] = 3] = "ERROR";
    Disposition[Disposition["SUCCESS"] = 4] = "SUCCESS";
})(Disposition = exports.Disposition || (exports.Disposition = {}));
function step(key, title, disposition, before, after, keywords = {}) {
    const newStep = Object.assign(Object.assign({ key,
        title,
        disposition,
        before,
        after }, SIMULATION_STEP_KEYWORD_ARGS), keywords);
    return newStep;
}
const ABILITY_REGEX = /%\{([^\{\}]+)\}/g;
const MACRO_REGEX = /#([a-z0-9\_\-]+)(?= |$)/gi;
const ATTRIBUTE_REGEX = /@\{([^\{\}]+)\}/g;
const ROLL_QUERY_REGEX = /\?\{((.|\n)+?)\}/g;
/** For highlighting use only. */
const HTML_ENTITY_REGEX = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/gi;
/** Avoids recursive self-expanding templates runaway. */
const MAXIMUM_TEMPLATE_LENGTH = 65536;
/** See https://help.roll20.net/hc/en-us/articles/360037773133-Dice-Reference#DiceReference-OrderofOperations. */
function* simulate(template, environment, isExpr = false) {
    for (let dirty = true, i = 1; i < 100 && dirty; i++) {
        if (!templateLengthValid(template))
            return templateLengthError(template);
        dirty = false;
        const matchAbility = [];
        const offsetAbility = [];
        const templateAbility = template.replace(ABILITY_REGEX, (match, p1, offset) => {
            matchAbility.push({ name: match });
            offsetAbility.push([offset, offset + match.length]);
            return environment.getProperty(match) || p1;
        });
        const dirtyAbility = templateAbility !== template;
        dirty || (dirty = dirtyAbility);
        yield step(`ABILITY-${i}`, `Iteration ${i}: Ability expansion`, significantIf(dirtyAbility), template, templateAbility, { environmentProperties: matchAbility, highlightRanges: offsetAbility });
        template = templateAbility;
        if (!templateLengthValid(template))
            return templateLengthError(template);
        const matchMacro = [];
        const offsetMacro = [];
        const templateMacro = template.replace(MACRO_REGEX, (match, p1, offset) => {
            matchMacro.push({ name: match });
            offsetMacro.push([offset, offset + match.length]);
            return environment.getProperty(match) || p1;
        });
        const dirtyMacro = templateMacro !== template;
        dirty || (dirty = dirtyMacro);
        yield step(`MACRO-${i}`, `Iteration ${i}: Macro expansion`, significantIf(dirtyMacro), template, templateMacro, { environmentProperties: matchMacro, highlightRanges: offsetMacro });
        template = templateMacro;
        if (!templateLengthValid(template))
            return templateLengthError(template);
        const matchAttribute = [];
        const offsetAttribute = [];
        const templateAttribute = template.replace(ATTRIBUTE_REGEX, (match, p1, offset) => {
            matchAttribute.push({ name: match });
            offsetAttribute.push([offset, offset + match.length]);
            return environment.getProperty(match) || p1;
        });
        const dirtyAttribute = templateAttribute !== template;
        dirty || (dirty = dirtyAttribute);
        yield step(`ATTRIBUTE-${i}`, `Iteration ${i}: Attribute expansion`, significantIf(dirtyAttribute), template, templateAttribute, {
            environmentProperties: matchAttribute,
            highlightRanges: offsetAttribute,
        });
        template = templateAttribute;
    }
    for (let dirty = true, i = 1; i < 100 && dirty; i++) {
        if (!templateLengthValid(template))
            return templateLengthError(template);
        dirty = false;
        const matchRollQuery = [];
        const offsetRollQuery = [];
        ROLL_QUERY_REGEX.lastIndex = 0;
        const templateRollQuery = template.replace(ROLL_QUERY_REGEX, (match, p1, p2, offset) => {
            var _a;
            const correctedMatch = (0, environment_1.keyForString)(match, false);
            const defaultValue = rollQueryDefault(p1);
            const possibleValues = rollQueryChoices(p1);
            matchRollQuery.push({
                name: correctedMatch,
                defaultValue,
                possibleValues,
            });
            offsetRollQuery.push([offset, offset + match.length]);
            const matchObj = environment.getPropertyObject(match);
            // Do fuzzy matching, since the simulated values are more accurate than
            // the AST-generated values.
            if (possibleValues) {
                return (((_a = (0, environment_1.getClosestValue)(possibleValues, matchObj === null || matchObj === void 0 ? void 0 : matchObj.id, matchObj === null || matchObj === void 0 ? void 0 : matchObj.label, matchObj === null || matchObj === void 0 ? void 0 : matchObj.value)) === null || _a === void 0 ? void 0 : _a.value) ||
                    (matchObj === null || matchObj === void 0 ? void 0 : matchObj.value) ||
                    "");
            }
            return (matchObj === null || matchObj === void 0 ? void 0 : matchObj.value) || defaultValue || (matchObj === null || matchObj === void 0 ? void 0 : matchObj.defaultValue) || "";
        });
        const dirtyRollQuery = templateRollQuery !== template;
        dirty || (dirty = dirtyRollQuery);
        yield step(`ROLLQUERY-${i}`, `Iteration ${i}: Roll query expansion`, significantIf(dirtyRollQuery), template, templateRollQuery, {
            environmentProperties: matchRollQuery,
            highlightRanges: offsetRollQuery,
        });
        template = templateRollQuery;
    }
    let exprSucceeded = false;
    if (isExpr) {
        try {
            const evaluation = (0, evaluator_1.evaluate)(template);
            const templateExpr = String(evaluation.result);
            yield step(`EXPRESSION`, "Expression evaluation", maxDisposition(evaluation), template, templateExpr, {
                highlightRanges: [[0, template.length]],
                annotations: evaluation.annotations,
                result: evaluation,
            });
            template = templateExpr;
            exprSucceeded = true;
        }
        catch (error) {
            // Fall through. The expression might be a substitution that isn't
            // actually an expression, even though we can't tell up front.
        }
    }
    let inlineRollNumber = 0;
    if (!exprSucceeded) {
        while (true) {
            try {
                const parser = new parser_1.Parser();
                const ast = parser.parse(template);
                const result = findInlineRoll(ast);
                if (result) {
                    const [start, end] = result.location;
                    try {
                        const evaluation = (0, evaluator_1.evaluate)(result);
                        const templateInlineRoll = [
                            template.slice(0, start),
                            evaluation.result,
                            template.slice(end),
                        ].join("");
                        yield step(`INLINE-${++inlineRollNumber}`, "Inline roll execution", maxDisposition(evaluation), template, templateInlineRoll, {
                            highlightRanges: [[start, end]],
                            annotations: evaluation.annotations,
                            result: evaluation,
                        });
                        template = templateInlineRoll;
                    }
                    catch (error) {
                        return step(`5-${++inlineRollNumber}`, `Inline roll execution`, Disposition.ERROR, template, template, {
                            errorMessage: messageFrom(error),
                            highlightRanges: [[start, end]],
                        });
                    }
                }
                else {
                    yield step(`INLINE-${++inlineRollNumber}`, "Inline roll execution", Disposition.EMPTY, template, template);
                    break;
                }
            }
            catch (error) {
                return step(`INLINE-${++inlineRollNumber}`, "Inline roll execution", Disposition.ERROR, template, template, { errorMessage: messageFrom(error) });
            }
        }
    }
    const templateHtmlEntities = (0, html_entities_1.decode)(template, { scope: "strict" });
    const dirtyHtmlEntities = templateHtmlEntities !== template;
    yield step("HTML", "Final HTML entity expansion", significantIf(dirtyHtmlEntities), template, templateHtmlEntities, { highlightRanges: htmlEntityRanges(template) });
    template = templateHtmlEntities;
    return step("FINAL", "Success", Disposition.SUCCESS, template, template);
}
exports.simulate = simulate;
function simulateAll(template, environment, isExpr = false) {
    const steps = [];
    const simulation = simulate(template, environment, isExpr);
    let next;
    while (((next = simulation.next()), !next.done)) {
        steps.push(next.value);
    }
    steps.push(next.value); // final return
    return steps;
}
exports.simulateAll = simulateAll;
/** For highlighting use only. */
function htmlEntityRanges(template) {
    const ranges = [];
    let match;
    HTML_ENTITY_REGEX.lastIndex = 0;
    while ((match = HTML_ENTITY_REGEX.exec(template)) !== null) {
        ranges.push([match.index, match.index + match[0].length]);
    }
    return ranges;
}
/** Finds the first inline-roll. May be nested. */
function findInlineRoll(ast) {
    let returnValue;
    (0, model_1.visit)(ast, (node, _parents) => {
        if (node.type === "inline-roll") {
            returnValue = node;
            return true;
        }
    });
    return returnValue;
}
function significantIf(condition) {
    return condition ? Disposition.SIGNIFICANT : Disposition.EMPTY;
}
function maxDisposition(evaluation) {
    return evaluation.annotations.filter((x) => x.severity === model_1.AnnotationSeverity.ERROR).length
        ? Disposition.ERROR
        : evaluation.annotations.filter((x) => x.severity === model_1.AnnotationSeverity.WARNING).length
            ? Disposition.WARNING
            : Disposition.SIGNIFICANT;
}
function rollQueryDefault(rollQueryMatch) {
    const pipeSeparated = rollQueryMatch.split("|");
    if (pipeSeparated.length === 2) {
        return (0, html_entities_1.decode)(pipeSeparated[1].trim());
    }
    return undefined;
}
function rollQueryChoices(rollQueryMatch) {
    const pipeSeparated = rollQueryMatch.split("|");
    const result = [];
    if (pipeSeparated.length > 2) {
        for (let i = 1; i < pipeSeparated.length; i++) {
            const commaSeparated = pipeSeparated[i].split(",", 2);
            if (commaSeparated.length === 1) {
                const child = (0, html_entities_1.decode)(commaSeparated[0].trim());
                result.push([child, child]);
            }
            else {
                const key = (0, html_entities_1.decode)(commaSeparated[0].trim());
                const value = (0, html_entities_1.decode)(commaSeparated[1].trim());
                result.push([key, value]);
            }
        }
        return result;
    }
    return undefined;
}
function templateLengthValid(template) {
    return template.length <= MAXIMUM_TEMPLATE_LENGTH;
}
function templateLengthError(template) {
    return step("TEMPLATE-LENGTH-ERROR", "Template overflow", Disposition.ERROR, template, template, {
        errorMessage: `Template too long: ${template.length} bytes. Check your template for recursion.`,
    });
}
function messageFrom(error) {
    if (error === null)
        return "";
    else if (typeof error === "string")
        return error;
    else if (typeof error === "object" && "message" in error) {
        return error.message;
    }
    return String(error);
}
