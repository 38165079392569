"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "learn" };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = ["href"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_highlight_button = (0, vue_1.resolveComponent)("highlight-button");
    const _component_renderer = (0, vue_1.resolveComponent)("renderer");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("dl", _hoisted_1, [
        ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.documentedNodes, (node, index) => {
            var _a;
            return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", { key: index }, [
                (0, vue_1.createElementVNode)("dt", null, [
                    (0, vue_1.createTextVNode)((0, vue_1.toDisplayString)(_ctx.docsFor(node).name) + ": " + (0, vue_1.toDisplayString)(node.excerpt((_a = _ctx.template) !== null && _a !== void 0 ? _a : '', 30)) + " ", 1 /* TEXT */),
                    (0, vue_1.createVNode)(_component_highlight_button, {
                        onClick: ($event) => (_ctx.$emit('click', node))
                    }, null, 8 /* PROPS */, ["onClick"])
                ]),
                (_ctx.docsFor(node).describe)
                    ? ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: 0 }, (0, vue_1.renderList)(_ctx.docsFor(node).describe(node), (p, pIndex) => {
                        return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("dd", { key: pIndex }, [
                            ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(p, (s, sIndex) => {
                                return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: sIndex }, [
                                    (typeof s === 'string')
                                        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: 0 }, [
                                            (0, vue_1.createTextVNode)((0, vue_1.toDisplayString)(s), 1 /* TEXT */)
                                        ], 64 /* STABLE_FRAGMENT */))
                                        : (_ctx.isNode(s))
                                            ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_renderer, {
                                                key: 1,
                                                nodes: [s],
                                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click', $event)))
                                            }, null, 8 /* PROPS */, ["nodes"]))
                                            : (_ctx.isNodes(s))
                                                ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_renderer, {
                                                    key: 2,
                                                    nodes: s,
                                                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('click', $event)))
                                                }, null, 8 /* PROPS */, ["nodes"]))
                                                : (0, vue_1.createCommentVNode)("v-if", true)
                                ], 64 /* STABLE_FRAGMENT */));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]));
                    }), 128 /* KEYED_FRAGMENT */))
                    : (0, vue_1.createCommentVNode)("v-if", true),
                (_ctx.linksFor(node).length)
                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("dd", _hoisted_2, [
                        (0, vue_1.createTextVNode)(" More about " + (0, vue_1.toDisplayString)(_ctx.docsFor(node).about) + ": ", 1 /* TEXT */),
                        ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.linksFor(node), (link, index) => {
                            return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: index }, [
                                index
                                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: 0 }, [
                                        (0, vue_1.createTextVNode)(" · ")
                                    ], 64 /* STABLE_FRAGMENT */))
                                    : (0, vue_1.createCommentVNode)("v-if", true),
                                (0, vue_1.createElementVNode)("a", {
                                    href: link.url,
                                    target: "_blank"
                                }, (0, vue_1.toDisplayString)(link.name), 9 /* TEXT, PROPS */, _hoisted_3)
                            ], 64 /* STABLE_FRAGMENT */));
                        }), 128 /* KEYED_FRAGMENT */))
                    ]))
                    : (0, vue_1.createCommentVNode)("v-if", true)
            ]));
        }), 128 /* KEYED_FRAGMENT */))
    ]));
}
exports.render = render;
