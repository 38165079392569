"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const state_1 = require("../../state");
exports.default = (0, vue_1.defineComponent)({
    setup() {
        const stateManager = (0, vue_1.inject)(state_1.STATE_MANAGER);
        if (!stateManager)
            throw new Error("No state found.");
        return Object.assign({}, (0, vue_1.toRefs)(stateManager.state));
    },
    computed: {
        debugErrors() {
            return JSON.stringify(this.errors);
        },
        environmentString() {
            return JSON.stringify(this.environment.toObject(), undefined, 2);
        },
    },
});
