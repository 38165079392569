"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const html_entities_1 = require("html-entities");
const vue_1 = require("vue");
const model_1 = require("../../parser/model");
exports.default = (0, vue_1.defineComponent)({
    name: "renderer",
    emits: ["click"],
    props: {
        nodes: Array,
        maxLength: {
            type: Number,
            default: 30,
        },
    },
    computed: {
        maxLengthOrInfinity() {
            return !this.maxLength || this.maxLength < 0
                ? Number.POSITIVE_INFINITY
                : this.maxLength;
        },
    },
    methods: {
        asErrorNode(node) {
            if (!(node instanceof model_1.ErrorNode)) {
                throw new Error(`Non error node: ${node.type}`);
            }
            return node;
        },
        asLinkNode(node) {
            if (!(node instanceof model_1.MarkdownLinkNode)) {
                throw new Error(`Non link node: ${node.type}`);
            }
            return node;
        },
        contents(node) {
            return node
                .childNodes()
                .filter((x) => x.type === "text")
                .map((x) => x.value)
                .join("");
        },
        childrenOf(node) {
            if (!(node instanceof model_1.CompoundNode)) {
                throw new Error(`Non compound node: ${node.type}`);
            }
            return node.children;
        },
        decodedValue(node) {
            if (!(node instanceof model_1.TextNode)) {
                throw new Error(`Non text node: ${node.type}`);
            }
            return (0, html_entities_1.decode)(node.value, { scope: "strict" });
        },
        isButton(node) {
            const { ABILITY, API_COMMAND, API_COMMAND_IMMEDIATE } = model_1.MarkdownLinkType;
            return (node.type === "markdown-link" &&
                [ABILITY, API_COMMAND, API_COMMAND_IMMEDIATE].includes(node.linkType()));
        },
        isCompound(node) {
            return node.type === "quote" || node.type === "group";
        },
        summarize(nodes) {
            return (0, model_1.summarize)(nodes, this.maxLengthOrInfinity);
        },
    },
});
