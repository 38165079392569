"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = {
    key: 0,
    class: "card-body"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.tabController.isActive(_ctx.tabId))
        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [
            (0, vue_1.renderSlot)(_ctx.$slots, "default")
        ]))
        : (0, vue_1.createCommentVNode)("v-if", true);
}
exports.render = render;
