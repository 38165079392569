"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = {
    key: 0,
    class: "preview"
};
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { key: 2 };
const _hoisted_5 = { key: 3 };
const _hoisted_6 = { key: 4 };
const _hoisted_7 = { key: 5 };
const _hoisted_8 = { key: 6 };
const _hoisted_9 = { key: 7 };
const _hoisted_10 = { key: 8 };
const _hoisted_11 = { key: 9 };
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_renderer = (0, vue_1.resolveComponent)("renderer");
    return (_ctx.nodes.length)
        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("ul", _hoisted_1, [
            ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.nodes, (node, $index) => {
                return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: $index }, [
                    (node.type === 'chat')
                        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_2, [
                            (0, vue_1.createElementVNode)("strong", null, (0, vue_1.toDisplayString)(_ctx.characterName) + ":", 1 /* TEXT */),
                            (0, vue_1.createTextVNode)((0, vue_1.toDisplayString)(" ") + " "),
                            ((0, vue_1.openBlock)(), (0, vue_1.createBlock)((0, vue_1.resolveDynamicComponent)(_ctx.rendererOrTable(node)), {
                                nodes: _ctx.childrenOf(node),
                                showHighlightButton: _ctx.showHighlightButton,
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click', $event)))
                            }, null, 8 /* PROPS */, ["nodes", "showHighlightButton"]))
                        ]))
                        : (node.type === '/em')
                            ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_3, [
                                (0, vue_1.createElementVNode)("em", null, [
                                    (0, vue_1.createTextVNode)((0, vue_1.toDisplayString)(_ctx.characterName) + " ", 1 /* TEXT */),
                                    (0, vue_1.createVNode)(_component_renderer, {
                                        nodes: node.childNodes(),
                                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('click', $event)))
                                    }, null, 8 /* PROPS */, ["nodes"])
                                ])
                            ]))
                            : (node.type === '/w')
                                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_4, [
                                    (0, vue_1.createElementVNode)("strong", null, (0, vue_1.toDisplayString)(_ctx.characterName) + ":", 1 /* TEXT */),
                                    (0, vue_1.createTextVNode)(" (to "),
                                    (0, vue_1.createVNode)(_component_renderer, {
                                        nodes: _ctx.playerOrCharacter(node),
                                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('click', $event)))
                                    }, null, 8 /* PROPS */, ["nodes"]),
                                    (0, vue_1.createTextVNode)(") "),
                                    ((0, vue_1.openBlock)(), (0, vue_1.createBlock)((0, vue_1.resolveDynamicComponent)(_ctx.rendererOrTable(node)), {
                                        nodes: _ctx.childrenOf(node),
                                        showHighlightButton: _ctx.showHighlightButton,
                                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('click', $event)))
                                    }, null, 8 /* PROPS */, ["nodes", "showHighlightButton"]))
                                ]))
                                : (node.type === '/roll')
                                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_5, [
                                        (0, vue_1.createElementVNode)("strong", null, (0, vue_1.toDisplayString)(_ctx.characterName) + ":", 1 /* TEXT */),
                                        (0, vue_1.createTextVNode)(" rolling "),
                                        (0, vue_1.createVNode)(_component_renderer, {
                                            nodes: node.childNodes(),
                                            onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('click', $event)))
                                        }, null, 8 /* PROPS */, ["nodes"])
                                    ]))
                                    : (node.type === '/gmroll')
                                        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_6, [
                                            (0, vue_1.createElementVNode)("strong", null, (0, vue_1.toDisplayString)(_ctx.characterName) + ":", 1 /* TEXT */),
                                            (0, vue_1.createTextVNode)(" rolling "),
                                            (0, vue_1.createVNode)(_component_renderer, {
                                                nodes: node.childNodes(),
                                                onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('click', $event)))
                                            }, null, 8 /* PROPS */, ["nodes"])
                                        ]))
                                        : (node.type === '/ooc')
                                            ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_7, [
                                                (0, vue_1.createElementVNode)("strong", null, (0, vue_1.toDisplayString)(_ctx.playerName) + ":", 1 /* TEXT */),
                                                (0, vue_1.createTextVNode)((0, vue_1.toDisplayString)(" ") + " "),
                                                (0, vue_1.createVNode)(_component_renderer, {
                                                    nodes: node.childNodes(),
                                                    onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.$emit('click', $event)))
                                                }, null, 8 /* PROPS */, ["nodes"])
                                            ]))
                                            : (node.type === '/desc')
                                                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_8, [
                                                    (0, vue_1.createElementVNode)("em", null, [
                                                        ((0, vue_1.openBlock)(), (0, vue_1.createBlock)((0, vue_1.resolveDynamicComponent)(_ctx.rendererOrTable(node)), {
                                                            nodes: _ctx.childrenOf(node),
                                                            showHighlightButton: _ctx.showHighlightButton,
                                                            onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.$emit('click', $event)))
                                                        }, null, 8 /* PROPS */, ["nodes", "showHighlightButton"]))
                                                    ])
                                                ]))
                                                : (node.type === '/as')
                                                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_9, [
                                                        (0, vue_1.createElementVNode)("strong", null, [
                                                            (0, vue_1.createVNode)(_component_renderer, {
                                                                nodes: _ctx.playerOrCharacter(node),
                                                                onClick: _cache[8] || (_cache[8] = ($event) => (_ctx.$emit('click', $event)))
                                                            }, null, 8 /* PROPS */, ["nodes"]),
                                                            (0, vue_1.createTextVNode)(":")
                                                        ]),
                                                        (0, vue_1.createTextVNode)((0, vue_1.toDisplayString)(" ") + " "),
                                                        ((0, vue_1.openBlock)(), (0, vue_1.createBlock)((0, vue_1.resolveDynamicComponent)(_ctx.rendererOrTable(node)), {
                                                            nodes: _ctx.childrenOf(node),
                                                            showHighlightButton: _ctx.showHighlightButton,
                                                            onClick: _cache[9] || (_cache[9] = ($event) => (_ctx.$emit('click', $event)))
                                                        }, null, 8 /* PROPS */, ["nodes", "showHighlightButton"]))
                                                    ]))
                                                    : (node.type === '/emas')
                                                        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_10, [
                                                            (0, vue_1.createElementVNode)("em", null, [
                                                                (0, vue_1.createVNode)(_component_renderer, {
                                                                    nodes: _ctx.playerOrCharacter(node),
                                                                    onClick: _cache[10] || (_cache[10] = ($event) => (_ctx.$emit('click', $event)))
                                                                }, null, 8 /* PROPS */, ["nodes"]),
                                                                (0, vue_1.createTextVNode)((0, vue_1.toDisplayString)(" ") + " "),
                                                                (0, vue_1.createVNode)(_component_renderer, {
                                                                    nodes: _ctx.childrenOf(node),
                                                                    onClick: _cache[11] || (_cache[11] = ($event) => (_ctx.$emit('click', $event)))
                                                                }, null, 8 /* PROPS */, ["nodes"])
                                                            ])
                                                        ]))
                                                        : ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", _hoisted_11, [
                                                            (0, vue_1.createVNode)(_component_renderer, {
                                                                nodes: [node],
                                                                maxLength: -1,
                                                                onClick: _cache[12] || (_cache[12] = ($event) => (_ctx.$emit('click', $event)))
                                                            }, null, 8 /* PROPS */, ["nodes"])
                                                        ]))
                ], 64 /* STABLE_FRAGMENT */));
            }), 128 /* KEYED_FRAGMENT */))
        ]))
        : (0, vue_1.createCommentVNode)("v-if", true);
}
exports.render = render;
