"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEST_ONLY = exports.evaluate = exports.DiceEvaluationType = void 0;
const parser_1 = require("./parser");
const model_1 = require("./parser/model");
const KEEP_DROP_MODIFIER_TYPES = [
    "modifier-k",
    "modifier-kh",
    "modifier-kl",
    "modifier-d",
    "modifier-dh",
    "modifier-dl",
];
var DiceEvaluationType;
(function (DiceEvaluationType) {
    DiceEvaluationType[DiceEvaluationType["M"] = 0] = "M";
    DiceEvaluationType[DiceEvaluationType["SUM"] = 1] = "SUM";
    DiceEvaluationType[DiceEvaluationType["SUCCESS"] = 2] = "SUCCESS";
})(DiceEvaluationType = exports.DiceEvaluationType || (exports.DiceEvaluationType = {}));
function evaluate(template, fixture) {
    let parseAnnotations = [];
    if (typeof template === "string") {
        const parser = new parser_1.Parser();
        const [result, errors] = parser.tryParseExpression(template);
        if (!result) {
            throw new Error(`Syntax error: ${errors === null || errors === void 0 ? void 0 : errors.message}`);
        }
        template = result;
        parseAnnotations = result.collectAnnotations();
    }
    // Prepend annotations at the root rather than collecting through traversal.
    const evaluation = new Evaluator(fixture ? diceFunctionFrom(fixture) : randomDiceFunction).evaluate(template);
    evaluation.annotations = [...parseAnnotations, ...evaluation.annotations];
    return evaluation;
}
exports.evaluate = evaluate;
class Evaluator {
    constructor(dieFunction) {
        this.dieFunction = dieFunction;
    }
    combine(dice, resultFn, summaryFn, ignore = false) {
        const evaluations = dice.map((x) => this.evaluate(x));
        const summary = summaryFn(...evaluations.map((x) => x.summary));
        const result = resultFn(...evaluations.map((x) => x.result));
        if (!isFinite(result)) {
            throw new Error(`Division by zero: ${summaryToText(summary)} = ${result}`);
        }
        return {
            result,
            success: evaluations.reduce((a, b) => a + b.success, 0),
            failure: evaluations.reduce((a, b) => a + b.failure, 0),
            ignore,
            summary,
            inlineRolls: evaluations.reduce((a, b) => (a.push(...b.inlineRolls), a), []),
            annotations: [],
        };
    }
    evaluate(node) {
        const childNodes = node.childNodes();
        switch (node.type) {
            case "literal":
                const literalNode = node;
                return {
                    result: literalNode.value,
                    success: 0,
                    failure: 0,
                    ignore: false,
                    summary: [String(literalNode.value)],
                    inlineRolls: [],
                    annotations: [],
                };
            case "+":
                return this.combine(node.childNodes(), (op1, op2) => op1 + op2, (op1, op2) => [...op1, "+", ...op2]);
            case "-":
                if (childNodes.length === 1) {
                    return this.combine(node.childNodes(), (op) => -op, (op) => [...op]);
                }
                else {
                    return this.combine(node.childNodes(), (op1, op2) => op1 - op2, (op1, op2) => [...op1, "-", ...op2]);
                }
            case "*":
                return this.combine(node.childNodes(), (op1, op2) => op1 * op2, (op1, op2) => [...op1, "*", ...op2]);
            case "/":
                return this.combine(node.childNodes(), (op1, op2) => op1 / op2, (op1, op2) => [...op1, "/", ...op2]);
            case "%":
                return this.combine(node.childNodes(), (op1, op2) => op1 % op2, (op1, op2) => [...op1, "%", ...op2]);
            case "**":
                return this.combine(node.childNodes(), (op1, op2) => Math.pow(op1, op2), (op1, op2) => [...op1, "**", ...op2]);
            case "paren":
                return this.combine(node.childNodes(), (op) => op, (op) => ["(", ...op, ")"]);
            case "label":
                const labelNode = node;
                // Ideally at this point all the string substitution has already happened,
                // and inline rolls don't really work from a Roll20 parsing level,
                // so if anything survived the best we can do is thread it back together.
                return this.combine([labelNode.node], (op) => op, (op) => [...op, "[", (0, model_1.summarize)(labelNode.value), "]"]);
            case "abs":
                return this.combine(node.childNodes(), (op) => Math.abs(op), (op) => ["abs(", ...op, ")"]);
            case "ceil":
                return this.combine(node.childNodes(), (op) => Math.ceil(op), (op) => ["ceil(", ...op, ")"]);
            case "floor":
                return this.combine(node.childNodes(), (op) => Math.floor(op), (op) => ["floor(", ...op, ")"]);
            case "round":
                return this.combine(node.childNodes(), (op) => Math.round(op), (op) => ["round(", ...op, ")"]);
            case "inline-roll":
                const result = this.evaluate(node.childNodes()[0]);
                return {
                    result: result.result,
                    success: result.success,
                    failure: result.failure,
                    summary: [String(result.result)],
                    ignore: false,
                    inlineRolls: [...result.inlineRolls, result],
                    annotations: [],
                };
            case "dice":
                return this.evaluateDice(node);
            case "grouped-dice":
                const annotations = [];
                const groupNode = node;
                const results = groupNode.children.map((x) => this.evaluate(x));
                checkSupportedModifiers(groupNode, annotations, ...KEEP_DROP_MODIFIER_TYPES);
                applyKeepDrop(groupNode, groupNode.modifiers, annotations, results);
                return {
                    result: results.reduce((total, x) => total + (x.ignore ? 0 : x.result), 0),
                    success: results.reduce((total, x) => total + (x.ignore ? 0 : x.success), 0),
                    failure: results.reduce((total, x) => total + (x.ignore ? 0 : x.failure), 0),
                    summary: summarize(results, "{", ", ", "}"),
                    ignore: false,
                    inlineRolls: results.reduce((agg, x) => (agg.push(...x.inlineRolls), agg), []),
                    annotations: results.reduce((agg, x) => (agg.push(...annotations), agg), []),
                };
            case "error":
                throw new Error(node.message);
            default:
                throw new Error(`Evaluator not implemented: ${node.type}`);
        }
    }
    evaluateDice(node) {
        const inlineRolls = [];
        const annotations = [];
        if (node instanceof model_1.DiceNode) {
            checkSupportedModifiers(node, annotations, ...KEEP_DROP_MODIFIER_TYPES);
            let numDice;
            if (!node.numDice) {
                numDice = 1;
            }
            else {
                const numDiceResult = this.evaluate(node.numDice);
                numDice = Math.round(numDiceResult.result);
                inlineRolls.push(...numDiceResult.inlineRolls);
                if (numDice < 0) {
                    throw new Error(`Unsupported die count: ${numDiceResult.summary}.`);
                }
            }
            let numSides;
            if (node.numSides.type === "fate") {
                numSides = "F";
            }
            else {
                const numSidesResult = this.evaluate(node.numSides);
                numSides = Math.round(numSidesResult.result);
                inlineRolls.push(...numSidesResult.inlineRolls);
                if (numSides < 0) {
                    throw new Error(`Unsupported die type: ${numSidesResult.summary}.`);
                }
            }
            let results = [];
            for (let i = 0; i < numDice; i++) {
                const roll = this.dieFunction(numSides);
                results.push({
                    result: roll,
                    success: roll === numSides ? 1 : 0,
                    failure: roll === 1 && numSides != "F" ? 1 : 0,
                    ignore: false,
                    inlineRolls: [],
                    summary: [String(roll)],
                    annotations: [],
                });
            }
            applyKeepDrop(node, node.modifiers, annotations, results);
            return {
                result: results.reduce((total, x) => total + (x.ignore ? 0 : x.result), 0),
                success: results.reduce((total, x) => total + (x.ignore ? 0 : x.success), 0),
                failure: results.reduce((total, x) => total + (x.ignore ? 0 : x.failure), 0),
                ignore: false,
                summary: numSides === "F" ? [summarizeFate(results)] : summarize(results),
                inlineRolls,
                annotations: annotations,
            };
        }
        else {
            throw new Error(`Unexpected dice type: ${node.summarize()}.`);
        }
    }
}
var KeepDropMode;
(function (KeepDropMode) {
    KeepDropMode[KeepDropMode["KEEP_HIGHEST"] = 0] = "KEEP_HIGHEST";
    KeepDropMode[KeepDropMode["KEEP_LOWEST"] = 1] = "KEEP_LOWEST";
    KeepDropMode[KeepDropMode["DROP_HIGHEST"] = 2] = "DROP_HIGHEST";
    KeepDropMode[KeepDropMode["DROP_LOWEST"] = 3] = "DROP_LOWEST";
})(KeepDropMode || (KeepDropMode = {}));
/** In-place edits the results list, setting ignore=true as needed. */
function keepDropInPlace(results, mode, n) {
    const sortedResults = Array.from(results.keys()).sort((a, b) => results[a].result - results[b].result);
    let diceToDrop;
    switch (mode) {
        case KeepDropMode.KEEP_HIGHEST:
            diceToDrop = sortedResults.slice(0, -n);
            break;
        case KeepDropMode.KEEP_LOWEST:
            diceToDrop = sortedResults.slice(n);
            break;
        case KeepDropMode.DROP_HIGHEST:
            diceToDrop = sortedResults.slice(-n);
            break;
        case KeepDropMode.DROP_LOWEST:
            diceToDrop = sortedResults.slice(0, n);
    }
    diceToDrop.forEach((index) => (results[index].ignore = true));
}
function applyKeepDrop(node, modifiers, annotations, results) {
    var _a;
    const keepDrop = nodesOfType(modifiers, ...KEEP_DROP_MODIFIER_TYPES);
    if (keepDrop.length > 1) {
        annotations.push(new model_1.Annotation(`${node.summarize()} has ${keepDrop.length} keep/drop modifiers. Roll20 does not support this. Observing the first one.`, model_1.AnnotationSeverity.ERROR, node));
    }
    if (keepDrop.length > 0) {
        const n = (_a = integerNode(keepDrop[0])) !== null && _a !== void 0 ? _a : 1;
        switch (keepDrop[0].type) {
            case "modifier-d":
            case "modifier-dl":
                keepDropInPlace(results, KeepDropMode.DROP_LOWEST, n);
                break;
            case "modifier-dh":
                keepDropInPlace(results, KeepDropMode.DROP_HIGHEST, n);
                break;
            case "modifier-kl":
                keepDropInPlace(results, KeepDropMode.KEEP_LOWEST, n);
                break;
            case "modifier-kh":
            case "modifier-k":
                keepDropInPlace(results, KeepDropMode.KEEP_HIGHEST, n);
                break;
            default:
                throw new Error("Keep/drop not implemented");
        }
    }
}
function integerNode(node) {
    if (node.integerNode) {
        if (node.integerNode.type === "literal") {
            return node.integerNode.value;
        }
        else {
            throw new Error(`Could not get number from modifier ${node.summarize()}`);
        }
    }
    return undefined;
}
function summarize(evaluations, before = "(", between = "+", after = ")") {
    const returnArray = [before];
    for (let i = 0; i < evaluations.length; i++) {
        returnArray.push(evaluations[i], between);
    }
    returnArray[returnArray.length - 1] = after;
    return returnArray;
}
const FATE_MAP = "-0+";
function summarizeFate(evaluations) {
    return `(${evaluations.map((x) => FATE_MAP.charAt(x.result + 1)).join("")})`;
}
function summaryToText(summary) {
    return summary
        .map((x) => (typeof x === "string" ? x : summaryToText(x.summary)))
        .join("");
}
function diceFunctionFrom(fixture) {
    if (!fixture) {
        return randomDiceFunction;
    }
    else if (typeof fixture === "function") {
        return fixture;
    }
    else {
        return function (die) {
            function throwUnexpected() {
                throw new Error(`Unexpected request for a d${die}.`);
            }
            const dieType = `d${die}`;
            if (Array.isArray(fixture)) {
                const nextValue = fixture.shift();
                if (nextValue === undefined) {
                    throwUnexpected();
                }
                return nextValue;
            }
            else if (dieType in fixture) {
                const dieTypeValues = fixture[dieType];
                if (Array.isArray(dieTypeValues)) {
                    const nextValue = dieTypeValues.shift();
                    if (nextValue === undefined) {
                        throwUnexpected();
                    }
                    return nextValue;
                }
                else {
                    const nextValue = dieTypeValues;
                    delete fixture[dieType];
                    return nextValue;
                }
            }
            else {
                throwUnexpected();
            }
        };
    }
}
function randomDiceFunction(die) {
    if (die === "F") {
        return Math.floor(Math.random() * 3) - 1;
    }
    else {
        // TODO: Fix. https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#Potential_sources_of_bias
        return Math.floor(Math.random() * die) + 1;
    }
}
function nodesOfType(nodes, ...types) {
    const typeSet = new Set(types);
    return nodes.filter((x) => typeSet.has(x.type));
}
function checkSupportedModifiers(node, annotations, ...types) {
    const typeSet = new Set(types);
    node.modifiers.forEach((x) => {
        if (!typeSet.has(x.type)) {
            annotations.push(new model_1.Annotation(`${node.summarize()} has unsupported modifier ${x.summarize()}.`, model_1.AnnotationSeverity.ERROR, x));
        }
    });
}
function evaluateDiceForTest(node, dieFunction) {
    return new Evaluator(dieFunction).evaluateDice(node);
}
exports.TEST_ONLY = {
    diceFunctionFrom,
    evaluateDice: evaluateDiceForTest,
    summarize,
    summarizeFate,
};
