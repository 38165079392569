"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLAYER_NAME = exports.CHARACTER_NAME = void 0;
const vue_1 = require("vue");
const environment_1 = require("../../environment");
const state_1 = require("../../state");
exports.CHARACTER_NAME = "characterName";
exports.PLAYER_NAME = "playerName";
let nextId = 0;
exports.default = (0, vue_1.defineComponent)({
    props: {
        choices: {
            type: Array,
            default: [],
        },
        label: String,
        field: { type: String, required: true },
        placeholder: String,
        wide: { type: Boolean, default: false },
    },
    setup(props, context) {
        const stateManager = (0, vue_1.inject)(state_1.STATE_MANAGER);
        if (!stateManager)
            throw new Error("Cannot find state manager");
        let source;
        let sourceObj = (0, vue_1.ref)();
        let autoLabel;
        let autoPlaceholder;
        let choiceGroups = (0, vue_1.ref)([]);
        const inputId = `environmentField${String(nextId++).padStart(8, "0")}`;
        if (!stateManager)
            throw new Error("Cannot find state manager");
        if (props.field === exports.CHARACTER_NAME) {
            source = (0, vue_1.toRef)(stateManager.state.environment, "characterName");
            autoLabel = (0, vue_1.computed)(() => props.label || "Character name");
            autoPlaceholder = (0, vue_1.computed)(() => props.placeholder || "Character");
        }
        else if (props.field === exports.PLAYER_NAME) {
            source = (0, vue_1.toRef)(stateManager.state.environment, "playerName");
            autoLabel = (0, vue_1.computed)(() => props.label || "Player name");
            autoPlaceholder = (0, vue_1.computed)(() => props.placeholder || "Player");
        }
        else {
            sourceObj = (0, vue_1.computed)(() => {
                return stateManager.state.environment.getPropertyObject(props.field);
            });
            source = (0, vue_1.computed)({
                get() {
                    var _a;
                    return ((_a = sourceObj.value) === null || _a === void 0 ? void 0 : _a.value) || "";
                },
                set(value) {
                    const obj = stateManager.state.environment.getPropertyObject(props.field, true);
                    obj.value = value;
                    for (const group of choiceGroups.value) {
                        const match = (0, environment_1.getClosestValue)(group, undefined, undefined, value);
                        if (match) {
                            obj.id = match.id;
                            obj.label = match.label;
                            return;
                        }
                    }
                    delete obj.id;
                    delete obj.label;
                },
            });
            autoLabel = (0, vue_1.computed)(() => props.field);
            autoPlaceholder = (0, vue_1.computed)(() => {
                var _a;
                return props.placeholder ||
                    ((_a = stateManager.state.environment.getPropertyObject(props.field)) === null || _a === void 0 ? void 0 : _a.defaultValue) ||
                    props.field;
            });
            choiceGroups = (0, vue_1.computed)(() => {
                var _a;
                const groups = [];
                groups.push(props.choices);
                const propertyPossibleValues = ((_a = stateManager.state.environment.getPropertyObject(props.field)) === null || _a === void 0 ? void 0 : _a.possibleValues) || [];
                if (!(0, environment_1.choicesAreEquivalent)(props.choices, propertyPossibleValues)) {
                    groups.push(propertyPossibleValues);
                }
                return groups.filter((x) => x.length); // remove empty groups
            });
        }
        const tall = (0, vue_1.computed)(() => props.field.startsWith("%") || props.field.startsWith("#"));
        const choose = (id, label, value) => {
            let obj = stateManager.state.environment.getPropertyObject(props.field, true);
            if (obj) {
                obj.id = id;
                obj.label = label;
                obj.value = value;
            }
        };
        const itemLabel = ([key, value]) => {
            if (key === value) {
                return truncate(key, 60);
            }
            else {
                return `${truncate(key)}: ${truncate(value)}`;
            }
        };
        const truncate = (text, length = 30) => {
            return text.length > length ? text.slice(0, length) + "…" : text;
        };
        return {
            autoLabel,
            autoPlaceholder,
            choiceGroups,
            choose,
            itemLabel,
            inputId,
            source,
            sourceObj,
            tall,
            truncate,
        };
    },
});
