"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXAMPLES = void 0;
exports.EXAMPLES = [
    {
        name: "Simple",
        description: "You can type anything into the template that you would " +
            "type into the chat pane.",
        code: "I think we should go over there.\n/me points to the mountains " +
            "to the northeast.",
    },
    {
        name: "Dice rolls",
        description: "You can type a dice roll directly into the pane for an " +
            "explanation.",
        code: "3d6kh2",
    },
    {
        name: "Inline rolls and roll commands",
        description: "You can also roll inline or use roll commands.",
        code: "/me wields his sword for [[d20]] damage.\n" +
            "/roll 3d4 + 2 HP restored",
    },
    {
        name: "Complex example",
        description: "Templates can be arbitrarily complex.",
        code: "&{template:default} {{name=?{Character Name}}} " +
            "{{Attribute 1=[[d12]]}} {{Attribute 2=?{Attr 2}}} " +
            "{{Attribute 3=**3**}} {{Attribute 4=%{selected|some_ability}}}",
    },
];
