"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
const ohm_parser_1 = require("./ohm-parser");
class Parser {
    constructor() { }
    /** Parses arbitrary text into an AST. */
    parse(text) {
        const [result, errors] = this.tryParse(text);
        if (errors)
            throw new Error(errors.shortMessage);
        if (!result)
            throw new Error("Undefined result");
        return result;
    }
    tryParse(text) {
        const result = ohm_parser_1.ohmParser.match(text, "lines");
        if (result.failed()) {
            return [undefined, errorDetailsFromMatchResult(result)];
        }
        else {
            return [(0, ohm_parser_1.ohmSemantics)(result).toNode(), undefined];
        }
    }
    /** Parses a roll expression into an AST. */
    parseExpression(expression) {
        const [result, errors] = this.tryParseExpression(expression);
        if (errors)
            throw new Error(errors === null || errors === void 0 ? void 0 : errors.shortMessage);
        if (!result)
            throw new Error("Undefined result");
        return result;
    }
    tryParseExpression(expression) {
        const result = ohm_parser_1.ohmParser.match(expression, "expression");
        if (result.failed()) {
            return [undefined, errorDetailsFromMatchResult(result)];
        }
        else {
            return [(0, ohm_parser_1.ohmSemantics)(result).toNode(), undefined];
        }
    }
}
exports.Parser = Parser;
function errorDetailsFromMatchResult(result) {
    return {
        expected: result.getExpectedText(),
        location: result.getRightmostFailurePosition(),
        message: result.message,
        shortMessage: result.shortMessage,
    };
}
