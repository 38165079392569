"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: "DiceEvaluation",
    props: {
        value: Object,
    },
    render() {
        if (!this.value || !this.value.summary) {
            return "N/A";
        }
        return this.value.summary.map((x) => convert(x));
    },
});
function convert(x) {
    if (typeof x === "string") {
        return x;
    }
    else {
        let colorClass;
        if (x.ignore) {
            colorClass = "text-muted";
        }
        else if (x.summary.length === 1 && typeof x.summary[0] === "string") {
            if (x.success && x.failure) {
                colorClass = "text-primary";
            }
            else if (x.success) {
                colorClass = "text-success";
            }
            else if (x.failure) {
                colorClass = "text-danger";
            }
        }
        return (0, vue_1.h)("span", { class: colorClass }, [x.summary.map((y) => convert(y))]);
    }
}
