"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDocumented = void 0;
const model_1 = require("./parser/model");
// TODO: Adopt further.
function para(strings, ...args) {
    const result = [];
    let index = 0;
    while (true) {
        if (index === strings.length)
            break;
        const nextString = strings[index];
        result.push(nextString);
        if (index === args.length)
            break;
        const nextArg = args[index];
        result.push(nextArg);
        index++;
    }
    return result;
}
const orderOfOperationsHc = helpCenter("Order of Operations", "https://roll20.zendesk.com/hc/en-us/articles/360037773133#DiceReference-OrderofOperations");
const orderOfOperationsWiki = wiki("Order of Operations", "https://wiki.roll20.net/Dice_Reference#Order_of_Operations");
const attributesAndAbilitiesHc = helpCenter("Journal", "https://roll20.zendesk.com/hc/en-us/articles/360039675133-Journal#Journal-Attributes&AbilitiesTab");
const attributesAndAbilitiesWiki = wiki("Journal", "https://wiki.roll20.net/Journal#Attributes_.26_Abilities_Tab");
const macrosCollectionsHc = helpCenter("Macros", "https://roll20.zendesk.com/hc/en-us/articles/360039178754");
const macrosCollectionsWiki = wiki("Macros", "https://wiki.roll20.net/Collections#Macros");
const markdownHc = helpCenter("Text Chat / Basic Formatting", "https://help.roll20.net/hc/en-us/articles/360039675093-Text-Chat#TextChat-BasicFormatting");
const markdownWiki = wiki("Text Chat / Basic Formatting", "https://wiki.roll20.net/Text_Chat#Basic_Formatting");
const docs = {
    comment: {
        name: "Comment",
        about: "comments",
        describe() {
            return [["Lines that begin with // are not shown in chat."]];
        },
        links: [],
    },
    "/roll": {
        name: "Roll command",
        about: "roll commands",
        describe(x) {
            const result = [
                para `Rolls the dice (${x.roll}) and sends the result to chat.`,
            ];
            if (x.children.length) {
                result.push(para `The chat text after the roll (${x.children}) describes it.`);
            }
            else {
                result.push(["You can add chat text after the roll to describe it."]);
            }
            return result;
        },
        links: [],
    },
    "/gmroll": {
        name: "GM roll",
        about: "GM rolls",
        describe(x) {
            const result = [
                ["Rolls the dice (", x.roll, ") and whispers the result to the GM."],
            ];
            if (x.children.length) {
                result.push([
                    "The chat text after the roll (",
                    x.children,
                    ") describes it.",
                ]);
            }
            else {
                result.push(["You can add chat text after the roll to describe it."]);
            }
            return result;
        },
        links: [],
    },
    "/w": {
        name: "Whisper",
        about: "whisper commands",
        describe() {
            return [["Whispers to another player."]];
        },
        links: [],
    },
    "/em": {
        name: "Emote",
        about: "emote commands",
        describe() {
            return [["Emotes to another player."]];
        },
        links: [],
    },
    "/ooc": {
        name: "OOC",
        about: "OOC commands",
        describe() {
            return [["Sends a chat under your player's name, not your token's."]];
        },
        links: [],
    },
    "/talktomyself": {
        name: "Talk to myself",
        about: "/talktomyself",
        describe() {
            return [
                ["Controls whether chat messages are sent to any other player."],
                [
                    'This can be followed by "off" or "on", or can be toggled by sending ' +
                        "it without either.",
                ],
            ];
        },
        links: [],
    },
    "/fx": {
        name: "FX",
        about: "FX commands",
        describe() {
            return [["Controls special effects on the map."]];
        },
        links: [],
    },
    "/desc": {
        name: "Description",
        about: "description commands",
        describe() {
            return [
                [
                    "For GMs only. Sends a description to the chat without it " +
                        "appearing to come from any particular character.",
                ],
            ];
        },
        links: [],
    },
    "/as": {
        name: "Chat as",
        about: "/as GM commands",
        describe() {
            return [["For GMs only. Sends a chat as the named character."]];
        },
        links: [],
    },
    "/emas": {
        name: "Emote as",
        about: "/emas GM commands",
        describe() {
            return [["For GMs only. Emotes named character."]];
        },
        links: [],
    },
    ability: {
        name: "Ability",
        about: "abilities",
        describe() {
            return [
                [
                    "Replaces this text with the macro-like ability belonging to " +
                        "the specified character (listed on the character sheet).",
                ],
            ];
        },
        links: [
            attributesAndAbilitiesHc,
            orderOfOperationsHc,
            attributesAndAbilitiesWiki,
            orderOfOperationsWiki,
        ],
    },
    macro: {
        name: "Macro",
        about: "macros",
        describe() {
            return [["Replaces this text with the macro of the given name."]];
        },
        links: [
            macrosCollectionsHc,
            orderOfOperationsHc,
            macrosCollectionsWiki,
            orderOfOperationsWiki,
        ],
    },
    "attribute-query": {
        name: "Attribute query",
        about: "attribute queries",
        describe() {
            return [
                [
                    "Replaces this text with the attribute value belonging to " +
                        "the specified character (listed on the character sheet).",
                ],
            ];
        },
        links: [
            attributesAndAbilitiesHc,
            orderOfOperationsHc,
            attributesAndAbilitiesWiki,
            orderOfOperationsWiki,
        ],
    },
    "inline-roll": {
        name: "Inline roll",
        about: "inline rolls",
        describe() {
            return [["Replaces this text with the result of the given dice roll."]];
        },
        links: [
            helpCenter("Text Chat", "https://roll20.zendesk.com/hc/en-us/articles/360039675093-Text-Chat#TextChat-Doingmathinthechat"),
            helpCenter("Dice Reference", "https://roll20.zendesk.com/hc/en-us/articles/360037773133#DiceReference-InlineDiceRolls"),
            wiki("Text Chat", "https://wiki.roll20.net/Text_Chat#Doing_math_in_the_chat"),
            wiki("Dice Reference", "https://wiki.roll20.net/Dice_Reference#Inline_Dice_Rolls"),
        ],
    },
    placeholder: {
        name: "Placeholder",
        about: "reusing rolls",
        describe(x) {
            return [
                [
                    `Undocumented feature: replaces this text with the result of an inline roll with index ${x.value}.`,
                ],
            ];
        },
        links: [wiki("Reusing Rolls", "https://wiki.roll20.net/Reusing_Rolls")],
    },
    "roll-query": {
        name: "Roll query",
        about: "roll queries",
        describe(x) {
            if (x.children.length < 2) {
                return [['Prompts the user for the value "', x.children[0], '".']];
            }
            else if (x.children.length === 2) {
                return [
                    [
                        'Prompts the user for the value "',
                        x.children[0],
                        '" and gives the default value "',
                        x.children[1],
                        '".',
                    ],
                ];
            }
            else {
                return [
                    [
                        'Prompts the user for the value "',
                        x.children[0],
                        '", giving ',
                        String(x.children.length - 1),
                        " dropdown options.",
                    ],
                ];
            }
        },
        links: [],
    },
    "roll-flag": {
        name: "Roll flag",
        about: "roll flags",
        describe() {
            return [
                [
                    "Applies the given roll flag to the roll or command. Commonly used to apply templates or to mark a roll result in the turn tracker.",
                ],
            ];
        },
        links: [],
    },
    label: {
        name: "Inline label",
        about: "inline labels",
        describe(x) {
            return [
                [
                    "Labels the expression to the immediate left, ",
                    x.node,
                    ', with the documentation label "',
                    x.value,
                    '".',
                ],
            ];
        },
        links: [],
    },
    dice: {
        name: "Dice roll",
        about: "dice rolls",
        describe(x) {
            const { numDice, numSides } = x;
            const oneDie = numDice === undefined ||
                (numDice instanceof model_1.ExprNode && numDice.calculate() === 1);
            if (numSides.type === "fate") {
                if (oneDie) {
                    return [["Rolls one FATE/Fudge die."]];
                }
                return [["Rolls ", numDice, " FATE/Fudge dice."]];
            }
            else {
                if (oneDie) {
                    return [["Rolls one ", x.numSides, "-sided die."]];
                }
                return [["Rolls ", x.numDice, " ", x.numSides, "-sided dice."]];
            }
        },
        links: [],
    },
    "grouped-dice": {
        name: "Grouped dice roll",
        about: "grouped dice rolls",
        describe(x) {
            if (x.children.length < 2) {
                return [
                    [
                        "Wraps the dice roll ",
                        x.children[0],
                        " so it can have " +
                            "additional modifiers applied to its modified value.",
                    ],
                ];
            }
            else {
                return [
                    [
                        "Evaluates the given ",
                        String(x.children.length),
                        " expressions and applies modifiers to the result.",
                    ],
                ];
            }
        },
        links: [],
    },
    table: {
        name: "Rollable table",
        about: "rollable tables",
        describe(x) {
            let line1;
            if (x.numDice &&
                (x.numDice.type !== "literal" ||
                    x.numDice.value !== 1)) {
                line1 = [
                    'Rolls the roll table named "',
                    x.tableName,
                    '" ',
                    x.numDice,
                    " times.",
                ];
            }
            else {
                line1 = ['Rolls the roll table named "', x.tableName, '".'];
            }
            return [
                line1,
                [
                    "If the roll table's result's entry name is numeric, " +
                        "the number is returned. Queries and inline rolls are not applied.",
                ],
            ];
        },
        links: [
            helpCenter("Collections: Rollable Tables", "https://help.roll20.net/hc/en-us/articles/360039178754-Collections#Collections-RollableTables"),
            wiki("Roll Table", "https://wiki.roll20.net/Roll_Table"),
        ],
    },
    "modifier-successes": {
        name: "Successes",
        about: "target numbers (successes)",
        describe() {
            return [['Counts results that match this condition as "successes".']];
        },
        links: [],
    },
    "modifier-!": {
        name: "Exploding",
        about: "exploding rolls",
        describe() {
            return [["When a die roll matches this result, another die is added."]];
        },
        links: [],
    },
    "modifier-!!": {
        name: "Compounding",
        about: "compounding rolls",
        describe() {
            return [
                [
                    "When a die roll matches this result, another die is added; " +
                        "the combined result counts as a single die.",
                ],
            ];
        },
        links: [],
    },
    "modifier-!p": {
        name: "Penetrating",
        about: "penetrating rolls",
        describe() {
            return [
                [
                    "When a die roll matches this result, another die is added, " +
                        "but 1 is subtracted from that die's total.",
                ],
            ];
        },
        links: [],
    },
    "modifier-f": {
        name: "Failures",
        about: "failure rolls",
        describe() {
            return [['Counts results that match this condition as "failures".']];
        },
        links: [],
    },
    "modifier-m": {
        name: "Matching",
        about: "matching rolls",
        describe() {
            return [["Shows dice results that match."]];
        },
        links: [],
    },
    "modifier-mt": {
        name: "Matches",
        about: "matching success rolls",
        describe() {
            return [["Returns the number of matches."]];
        },
        links: [],
    },
    "modifier-s": {
        name: "Sort",
        about: "sorting",
        describe() {
            return [["Sorts the results. Defaults to ascending order."]];
        },
        links: [],
    },
    "modifier-sa": {
        name: "Sort ascending",
        about: "sorting",
        describe() {
            return [["Sorts the results in ascending order."]];
        },
        links: [],
    },
    "modifier-sd": {
        name: "Sort descending",
        about: "sorting",
        describe() {
            return [["Sorts the results in descending order."]];
        },
        links: [],
    },
    "modifier-cs": {
        name: "Critical successes",
        about: "critical successes",
        describe() {
            return [
                [
                    "Highlights results that match the condition in green " +
                        'as "critical successes". Does not change rolling behavior.',
                ],
            ];
        },
        links: [],
    },
    "modifier-cf": {
        name: "Critical failures",
        about: "critical failures",
        describe() {
            return [
                [
                    "Highlights results that match the condition in green " +
                        'as "critical failure". Does not change rolling behavior.',
                ],
            ];
        },
        links: [],
    },
    "modifier-r": {
        name: "Reroll",
        about: "rerolls",
        describe() {
            return [["Rerolls and replaces results that match the given condition."]];
        },
        links: [],
    },
    "modifier-ro": {
        name: "Reroll only",
        about: "rerolls",
        describe() {
            return [
                [
                    "Rerolls and replaces results that match the given condition " +
                        "at most once.",
                ],
            ];
        },
        links: [],
    },
    "modifier-k": {
        name: "Keep highest",
        about: "keep modifiers",
        describe(x) {
            return [
                [
                    "Keeps the highest ",
                    x.integerNode,
                    " results from the modifier or group.",
                ],
            ];
        },
        links: [],
    },
    "modifier-kh": {
        name: "Keep highest",
        about: "keep modifiers",
        describe(x) {
            return [
                [
                    "Keeps the highest ",
                    x.integerNode,
                    " results from the modifier or group.",
                ],
            ];
        },
        links: [],
    },
    "modifier-kl": {
        name: "Keep lowest",
        about: "keep modifiers",
        describe(x) {
            return [
                [
                    "Keeps the lowest ",
                    x.integerNode,
                    " results from the modifier or group.",
                ],
            ];
        },
        links: [],
    },
    "modifier-d": {
        name: "Drop lowest",
        about: "keep modifiers",
        describe(x) {
            return [
                [
                    "Drops the lowest ",
                    x.integerNode,
                    " results from the modifier or group.",
                ],
            ];
        },
        links: [],
    },
    "modifier-dh": {
        name: "Drop highest",
        about: "keep modifiers",
        describe(x) {
            return [
                [
                    "Drops the highest ",
                    x.integerNode,
                    " results from the modifier or group.",
                ],
            ];
        },
        links: [],
    },
    "modifier-dl": {
        name: "Drop lowest",
        about: "keep modifiers",
        describe(x) {
            return [
                [
                    "Drops the lowest ",
                    x.integerNode,
                    " results from the modifier or group.",
                ],
            ];
        },
        links: [],
    },
    "template-row": {
        name: "Template row",
        about: "template rows",
        describe(x) {
            if (x.children.length === 1 && x.children[0] instanceof model_1.KeyValueNode) {
                const child = x.children[0];
                return [
                    [
                        'Configures the roll template with key "',
                        child.key,
                        '" with value "',
                        child.value,
                        '".',
                    ],
                ];
            }
            else {
                return [
                    [
                        'Configures the roll template to show a row containing "',
                        x.children,
                        '".',
                    ],
                ];
            }
        },
        links: [],
    },
    "markdown-bold": {
        name: "Markdown: bold",
        about: "Markdown syntax",
        describe(x) {
            return [["Renders the text in bold (<strong>)."]];
        },
        links: [markdownHc, markdownWiki],
    },
    "markdown-italic": {
        name: "Markdown: italic",
        about: "Markdown syntax",
        describe(x) {
            return [["Renders the text in italic (<em>)."]];
        },
        links: [markdownHc, markdownWiki],
    },
    "markdown-code": {
        name: "Markdown: code",
        about: "Markdown syntax",
        describe(x) {
            return [["Renders the text in monospace code format (<code>)."]];
        },
        links: [markdownHc, markdownWiki],
    },
    "markdown-link": {
        name: "Markdown: link/button/image",
        about: "Markdown syntax",
        describe(x) {
            let descriptionByType;
            switch (x.linkType()) {
                case model_1.MarkdownLinkType.ABILITY:
                    descriptionByType = "an ability command button";
                    break;
                case model_1.MarkdownLinkType.API_COMMAND:
                    descriptionByType = "an API command button";
                    break;
                case model_1.MarkdownLinkType.API_COMMAND_IMMEDIATE:
                    descriptionByType = "an API command button containing chat commands";
                    break;
                case model_1.MarkdownLinkType.IMAGE:
                    descriptionByType = "an image";
                    break;
                case model_1.MarkdownLinkType.LINK:
                    descriptionByType = "a link";
                    break;
                default:
                    descriptionByType = "a link, image, or button";
            }
            const docs = [[`Renders the text as ${descriptionByType}.`]];
            docs.push([
                "Markdown links that end in image extensions such as .gif " +
                    "or .jpg are automatically rendered as linked images.",
            ]);
            docs.push([
                'Links that begin with "~" run the macro ability with the ' +
                    "specified name.",
            ]);
            docs.push([
                'Links that begin with "!" run API commands with the ' +
                    "specified name, or run chat commands directly if prefixed with " +
                    '"!&#13;".',
            ]);
            return docs;
        },
        links: [
            markdownHc,
            markdownWiki,
            helpCenter("Macros: Ability Command Buttons", "https://help.roll20.net/hc/en-us/articles/360037256794-Macros#Macros-AbilityCommandButtons"),
            wiki("Macros: Ability Command Buttons", "https://wiki.roll20.net/Macros#Ability_Command_Buttons"),
            helpCenter("API Chat: API Command Buttons", "https://help.roll20.net/hc/en-us/articles/360037256754-API-Chat#API:Chat-APICommandButtons"),
            wiki("API Chat: API Command Buttons", "https://wiki.roll20.net/API:Chat#API_Command_Buttons"),
        ],
    },
};
function isDocumented(nodeOrNodes) {
    if (nodeOrNodes instanceof Array) {
        for (let node of nodeOrNodes) {
            if (isDocumented(node))
                return true;
        }
        return false;
    }
    else {
        return nodeOrNodes.type in docs;
    }
}
exports.isDocumented = isDocumented;
function helpCenter(...args) {
    if (args.length === 1) {
        return { name: "Help Center", url: args[0] };
    }
    else {
        return { name: `Help Center: ${args[0]}`, url: args[1] };
    }
}
function wiki(...args) {
    if (args.length === 1) {
        return { name: "Wiki", url: args[0] };
    }
    else {
        return { name: `Wiki: ${args[0]}`, url: args[1] };
    }
}
exports.default = docs;
