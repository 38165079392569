"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debug = exports.visit = exports.summarize = exports.Mapping = exports.TableNode = exports.ModifierNode = exports.GroupedDiceNode = exports.FateNode = exports.DiceNode = exports.MarkdownLinkNode = exports.MarkdownLinkType = exports.PlaceholderNode = exports.NumericLiteralNode = exports.KeyValueNode = exports.LabelNode = exports.ExprNode = exports.LineNode = exports.ErrorNode = exports.CompoundNode = exports.TextNode = exports.GenericNode = exports.RoundType = exports.SingleLineResultType = exports.Annotation = exports.AnnotationSeverity = void 0;
const ELLIPSIS = "…";
const ELLIPSIS_LENGTH = ELLIPSIS.length;
var AnnotationSeverity;
(function (AnnotationSeverity) {
    AnnotationSeverity[AnnotationSeverity["UNKNOWN"] = 0] = "UNKNOWN";
    AnnotationSeverity[AnnotationSeverity["INFO"] = 1] = "INFO";
    AnnotationSeverity[AnnotationSeverity["WARNING"] = 2] = "WARNING";
    AnnotationSeverity[AnnotationSeverity["ERROR"] = 3] = "ERROR";
})(AnnotationSeverity = exports.AnnotationSeverity || (exports.AnnotationSeverity = {}));
class Annotation {
    constructor(message, severity = AnnotationSeverity.WARNING, source) {
        this.message = message;
        this.severity = severity;
        this.source = source;
    }
    isError() {
        return this.severity === AnnotationSeverity.ERROR;
    }
    isWarning() {
        return this.severity === AnnotationSeverity.WARNING;
    }
    isInfo() {
        return this.severity === AnnotationSeverity.INFO;
    }
}
exports.Annotation = Annotation;
var SingleLineResultType;
(function (SingleLineResultType) {
    SingleLineResultType[SingleLineResultType["BLANK"] = 0] = "BLANK";
    SingleLineResultType[SingleLineResultType["EXPRESSION"] = 1] = "EXPRESSION";
    SingleLineResultType[SingleLineResultType["ROLL"] = 2] = "ROLL";
    SingleLineResultType[SingleLineResultType["GM_ROLL"] = 3] = "GM_ROLL";
    SingleLineResultType[SingleLineResultType["CHAT"] = 4] = "CHAT";
    SingleLineResultType[SingleLineResultType["WHISPER"] = 5] = "WHISPER";
    SingleLineResultType[SingleLineResultType["EMOTE"] = 6] = "EMOTE";
    SingleLineResultType[SingleLineResultType["OOC"] = 7] = "OOC";
    SingleLineResultType[SingleLineResultType["ESCAPED_TEXT"] = 8] = "ESCAPED_TEXT";
    SingleLineResultType[SingleLineResultType["TALK_TO_MYSELF"] = 9] = "TALK_TO_MYSELF";
    SingleLineResultType[SingleLineResultType["FX"] = 10] = "FX";
    SingleLineResultType[SingleLineResultType["DESC"] = 11] = "DESC";
    SingleLineResultType[SingleLineResultType["AS"] = 12] = "AS";
    SingleLineResultType[SingleLineResultType["EMOTE_AS"] = 13] = "EMOTE_AS";
    SingleLineResultType[SingleLineResultType["UNKNOWN"] = 14] = "UNKNOWN";
})(SingleLineResultType = exports.SingleLineResultType || (exports.SingleLineResultType = {}));
/**
 * All substitution rounds.
 *
 * @see https://roll20.zendesk.com/hc/en-us/articles/360037773133-Dice-Reference#DiceReference-OrderofOperations
 */
var RoundType;
(function (RoundType) {
    // Step 1: Abilities are expanded, e.g. %{character name|ability_name}.
    RoundType[RoundType["ABILITY"] = 0] = "ABILITY";
    // Step 2: Macros are expanded, e.g. #macro-name.
    RoundType[RoundType["MACRO"] = 1] = "MACRO";
    // Step 3: Attribute calls are resolved, e.g. @{attribute_name}.
    RoundType[RoundType["ATTRIBUTE_CALL"] = 2] = "ATTRIBUTE_CALL";
    // Step 4: Repeat steps 1-3 up to 99 levels deep or until there are no more
    // expansions required.
    // Step 5: Execute roll queries up to 99 levels deep, parsing HTML entities.
    RoundType[RoundType["ROLL_QUERY"] = 3] = "ROLL_QUERY";
    // Step 6: Execute inline rolls.
    RoundType[RoundType["INLINE_ROLL"] = 4] = "INLINE_ROLL";
    // Step 7a: Substitute the result for any dice.
    RoundType[RoundType["DICE_SUBSTITUTION"] = 5] = "DICE_SUBSTITUTION";
    // Step 7b: Evaluate the expression. Precedence: Parens, floor/round/ceil/abs,
    //     exponentiation, multiplcation/division/modulus, addition and subtraction.
    RoundType[RoundType["EVALUATE_EXPRESSION"] = 6] = "EVALUATE_EXPRESSION";
    // Step 8: Process HTML entities once more.
    RoundType[RoundType["HTML_ENTITY"] = 7] = "HTML_ENTITY";
})(RoundType = exports.RoundType || (exports.RoundType = {}));
class GenericNode {
    constructor(type, location) {
        this.type = type;
        this.location = location;
    }
    annotations() {
        return [];
    }
    childNodes() {
        return [];
    }
    debug() {
        return `(${this.type}${this.childNodes()
            .map((x) => ` ${"debug" in x ? x.debug() : "(?)"}`)
            .join("")})`;
    }
    excerpt(template, maxLength = Number.POSITIVE_INFINITY) {
        const [start, end] = this.location;
        const length = end - start;
        if (length > maxLength) {
            if (maxLength < ELLIPSIS_LENGTH)
                return "";
            return `${template.substr(start, maxLength - ELLIPSIS_LENGTH)}${ELLIPSIS}`;
        }
        return template.substr(start, length);
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        const basicStringLength = this.type.length + 2;
        if (maxLength < ELLIPSIS_LENGTH)
            return "";
        if (maxLength < basicStringLength)
            return ELLIPSIS;
        const summarizedChildNodes = summarize(this.childNodes(), maxLength - basicStringLength - 1);
        if (summarizedChildNodes.length) {
            return `(${this.type} ${summarizedChildNodes})`;
        }
        return `(${this.type})`;
    }
    isText() {
        return false;
    }
    collectAnnotations() {
        const returnArray = this.annotations();
        for (const childNode of this.childNodes()) {
            returnArray.push(...childNode.collectAnnotations());
        }
        return returnArray;
    }
}
exports.GenericNode = GenericNode;
class TextNode extends GenericNode {
    constructor(location, value) {
        super("text", location);
        this.location = location;
        this.value = value;
    }
    debug() {
        return `"${this.value}"`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        return truncate(this.value, maxLength);
    }
    isText(text) {
        if (text === undefined)
            return true;
        return this.value === text;
    }
    annotations() {
        if (this.value.includes("}")) {
            return [
                new Annotation(`Unmatched "}". This usually comes from a nested ?{} roll query that hasn't been properly escaped.`, AnnotationSeverity.WARNING, this),
            ];
        }
        return [];
    }
}
exports.TextNode = TextNode;
class CompoundNode extends GenericNode {
    constructor(type, location, children = []) {
        super(type, location);
        this.type = type;
        this.location = location;
        this.children = children;
        checkNodeArray(children);
    }
    annotations() {
        if (this.type === "inline-roll" && this.children.length > 1) {
            return [
                new Annotation("Inline roll contains non-expression text at the end. This may be an error.", AnnotationSeverity.WARNING, this),
            ];
        }
        else if (this.type === "markdown-html") {
            return [
                new Annotation("HTML tags will not be displayed.", AnnotationSeverity.WARNING, this),
            ];
        }
        return [];
    }
    childNodes() {
        return this.children;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        let string = "";
        switch (this.type) {
            case "ability":
                string = `%{${summarize(this.children, maxLength - 3, "|")}}`;
                break;
            case "attribute-query":
                string = `@{${summarize(this.children, maxLength - 3, "|")}}`;
                break;
            case "macro":
                string = `#${summarize(this.children, maxLength - 1, "")}`;
                break;
            case "roll-query":
                string = `?{${summarize(this.children, maxLength - 3, "|")}}`;
                break;
            case "roll-flag":
                string = `&{${summarize(this.children, maxLength - 3, ":")}}`;
                break;
            case "inline-roll":
                string = `[[${summarize(this.children, maxLength - 4)}]]`;
                break;
            case "group":
                string = summarize(this.children, maxLength);
                break;
            default:
                return super.summarize(maxLength);
        }
        if (string.length > maxLength) {
            if (maxLength < ELLIPSIS_LENGTH)
                return "";
            return ELLIPSIS;
        }
        return string;
    }
}
exports.CompoundNode = CompoundNode;
class ErrorNode extends CompoundNode {
    constructor(message, location, children = []) {
        super("error", location, children);
        this.message = message;
        this.location = location;
        this.children = children;
    }
    annotations() {
        return [new Annotation(this.message, AnnotationSeverity.ERROR, this)];
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        return summarize(this.children, maxLength);
    }
}
exports.ErrorNode = ErrorNode;
class LineNode extends CompoundNode {
    constructor(type, location, children = [], playerOrCharacter, roll) {
        super(type, location, children);
        this.type = type;
        this.location = location;
        this.children = children;
        this.playerOrCharacter = playerOrCharacter;
        this.roll = roll;
    }
    childNodes() {
        return flatten(this.playerOrCharacter, this.roll, this.children);
    }
}
exports.LineNode = LineNode;
const flattenedTypes = new Set([
    "+",
    "-",
    "*",
    "**",
    "/",
    "%",
]);
class ExprNode extends GenericNode {
    constructor(type, location, children = []) {
        super(type, location);
        this.type = type;
        this.location = location;
        this.children = children;
        this.rollFlags = [];
    }
    calculate() {
        const calculatedNullableChildren = this.children.map((x) => {
            if (x instanceof ExprNode)
                return x.calculate();
            if (x instanceof LabelNode)
                return x.node.calculate();
            if (x instanceof NumericLiteralNode)
                return x.value;
            return null;
        });
        if (calculatedNullableChildren.some((x) => x === null))
            return null;
        const calculatedChildren = calculatedNullableChildren;
        switch (this.type) {
            case "paren":
                return calculatedChildren[0];
            case "+":
                return calculatedChildren[0] + calculatedChildren[1];
            case "-":
                if (calculatedChildren.length === 1) {
                    return -calculatedChildren[0];
                }
                return calculatedChildren[0] - calculatedChildren[1];
            case "*":
                return calculatedChildren[0] * calculatedChildren[1];
            case "**":
                return Math.pow(calculatedChildren[0], calculatedChildren[1]);
            case "/":
                return calculatedChildren[0] / calculatedChildren[1];
            case "%":
                return calculatedChildren[0] % calculatedChildren[1];
            case "abs":
                return Math.abs(calculatedChildren[0]);
            case "floor":
                return Math.floor(calculatedChildren[0]);
            case "ceil":
                return Math.ceil(calculatedChildren[0]);
            case "round":
                return Math.round(calculatedChildren[0]);
        }
        throw new Error(`Calculation failed at node type "${this.type}".`);
    }
    childNodes() {
        return flatten(this.children, this.rollFlags);
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        let string = "";
        if (flattenedTypes.has(this.type)) {
            return summarize(this.flattenSummary(), maxLength);
        }
        switch (this.type) {
            case "abs":
            case "floor":
            case "ceil":
            case "round":
                string = `${this.type}(${summarize(this.children, maxLength - 2 - this.type.length)})`;
                break;
            case "paren":
                string = `(${summarize(this.children, maxLength - 2)})`;
                break;
            default:
                return super.summarize(maxLength);
        }
        if (string.length > maxLength) {
            if (maxLength < 1)
                return "";
            return ELLIPSIS;
        }
        return string;
    }
    flattenSummary() {
        const result = [];
        // This only handles binary operators, but let's be consistent.
        for (let i = 0; i < this.children.length; i += 1) {
            if (i !== 0)
                result.push(this.type);
            const node = this.children[i];
            if (node instanceof ExprNode && flattenedTypes.has(node.type)) {
                result.push(...node.flattenSummary());
            }
            else {
                result.push(node);
            }
        }
        return result;
    }
}
exports.ExprNode = ExprNode;
class LabelNode extends ExprNode {
    constructor(location, node, value) {
        super("label", location, [node]);
        this.location = location;
        this.node = node;
        this.value = value;
    }
    calculate() {
        return this.node.calculate();
    }
    childNodes() {
        return [this.node, ...this.value];
    }
    debug() {
        return `(label ${this.node.debug()} ${debug(this.value)})`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        if (maxLength < ELLIPSIS_LENGTH * 2 + 2) {
            return "";
        }
        let summarizedLabel = summarize(this.value, maxLength - ELLIPSIS_LENGTH - 2);
        let summarizedValue = this.node.summarize(maxLength - summarizedLabel.length - 3) || ELLIPSIS;
        return `${summarizedValue}[${summarizedLabel}]`;
    }
}
exports.LabelNode = LabelNode;
class KeyValueNode extends GenericNode {
    constructor(location, key, value) {
        super("key-value", location);
        this.location = location;
        this.key = key;
        this.value = value;
    }
    childNodes() {
        return flatten(this.key, this.value);
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        if (maxLength < ELLIPSIS_LENGTH * 2 + 1) {
            return "";
        }
        const key = summarize(this.key, maxLength - ELLIPSIS_LENGTH - 1);
        const value = summarize(this.value, maxLength - key.length - 1);
        return `${key}=${value}`;
    }
}
exports.KeyValueNode = KeyValueNode;
class NumericLiteralNode extends ExprNode {
    constructor(location, value) {
        super("literal", location);
        this.location = location;
        this.value = value;
    }
    calculate() {
        return this.value;
    }
    debug() {
        return `${this.value}`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        const result = String(this.value);
        if (result.length > maxLength) {
            if (maxLength < ELLIPSIS_LENGTH)
                return "";
            return ELLIPSIS;
        }
        return result;
    }
}
exports.NumericLiteralNode = NumericLiteralNode;
class PlaceholderNode extends GenericNode {
    constructor(location, value) {
        super("placeholder", location);
        this.location = location;
        this.value = value;
    }
    annotations() {
        return [
            new Annotation("Placeholders are an undocumented feature. Use with caution.", AnnotationSeverity.INFO, this),
        ];
    }
    debug() {
        return `(placeholder ${this.value})`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        const result = `$[[${this.value}]]`;
        if (result.length > maxLength) {
            if (maxLength < ELLIPSIS_LENGTH)
                return "";
            return ELLIPSIS;
        }
        return result;
    }
}
exports.PlaceholderNode = PlaceholderNode;
var MarkdownLinkType;
(function (MarkdownLinkType) {
    MarkdownLinkType["UNKNOWN"] = "UNKNOWN";
    MarkdownLinkType["LINK"] = "LINK";
    MarkdownLinkType["IMAGE"] = "IMAGE";
    MarkdownLinkType["ABILITY"] = "ABILITY";
    MarkdownLinkType["API_COMMAND"] = "API_COMMAND";
    MarkdownLinkType["API_COMMAND_IMMEDIATE"] = "API_COMMAND_IMMEDIATE";
})(MarkdownLinkType = exports.MarkdownLinkType || (exports.MarkdownLinkType = {}));
const IMAGE_SUFFIX = /\.(gif|jpe?g|png)$/;
class MarkdownLinkNode extends GenericNode {
    constructor(location, text, link) {
        super("markdown-link", location);
        this.location = location;
        this.text = text;
        this.link = link;
    }
    childNodes() {
        return [...this.text, ...this.link];
    }
    linkType() {
        const headNode = textValue(this.link[0]);
        const tailNode = textValue(this.link[this.link.length - 1]);
        if (headNode === null || headNode === void 0 ? void 0 : headNode.startsWith("~")) {
            return MarkdownLinkType.ABILITY;
        }
        else if (headNode === null || headNode === void 0 ? void 0 : headNode.startsWith("!&#13;")) {
            return MarkdownLinkType.API_COMMAND_IMMEDIATE;
        }
        else if (headNode === null || headNode === void 0 ? void 0 : headNode.startsWith("!")) {
            return MarkdownLinkType.API_COMMAND;
        }
        else if (IMAGE_SUFFIX.exec(tailNode !== null && tailNode !== void 0 ? tailNode : "")) {
            return MarkdownLinkType.IMAGE;
        }
        else if (this.linkIsStatic()) {
            return MarkdownLinkType.LINK;
        }
        else {
            return MarkdownLinkType.UNKNOWN;
        }
    }
    linkIsStatic() {
        return this.link.every((x) => x.type === "text");
    }
    debug() {
        return `(markdown-link ${debug(this.text)} ${debug(this.link)})`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        const textLength = (maxLength - 4) * 0.75;
        const linkLength = maxLength - 4 - textLength;
        const result = `[${summarize(this.text, textLength)}](${summarize(this.text, linkLength)})`;
        if (result.length > maxLength) {
            if (maxLength < ELLIPSIS_LENGTH)
                return "";
            return ELLIPSIS;
        }
        return result;
    }
}
exports.MarkdownLinkNode = MarkdownLinkNode;
class DiceNode extends GenericNode {
    constructor(location, numDice, numSides, modifiers = []) {
        super("dice", location);
        this.location = location;
        this.numDice = numDice;
        this.numSides = numSides;
        this.modifiers = modifiers;
    }
    childNodes() {
        return flatten(this.numDice, this.numSides, this.modifiers);
    }
    debug() {
        var _a;
        return `(dice ${((_a = this.numDice) === null || _a === void 0 ? void 0 : _a.debug()) || ""}d${this.numSides.debug()}${this.modifiers
            .map((x) => x.debug())
            .join("")})`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        return summarize([this.numDice, "d", this.numSides, ...this.modifiers]);
    }
}
exports.DiceNode = DiceNode;
class FateNode extends GenericNode {
    constructor(location) {
        super("fate", location);
        this.location = location;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        if (maxLength >= 1)
            return "F";
        else
            return "";
    }
}
exports.FateNode = FateNode;
class GroupedDiceNode extends GenericNode {
    constructor(location, children, modifiers = []) {
        super("grouped-dice", location);
        this.location = location;
        this.children = children;
        this.modifiers = modifiers;
    }
    childNodes() {
        return flatten(this.children, this.modifiers);
    }
    debug() {
        return `(grouped-dice {${this.children
            .map((x) => x.debug())
            .join(",")}}${this.modifiers.map((x) => x.debug()).join("")})`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        if (maxLength < 2 * ELLIPSIS_LENGTH + 2) {
            return "";
        }
        const modifiers = summarize(this.modifiers, maxLength - ELLIPSIS_LENGTH + 1);
        const groups = summarize(this.children, maxLength - modifiers.length - 2, ",");
        return `{${groups}}${modifiers}`;
    }
}
exports.GroupedDiceNode = GroupedDiceNode;
class ModifierNode extends GenericNode {
    constructor(type, location, cpType = "", cpNode, integerNode) {
        super(type, location);
        this.cpType = cpType;
        this.cpNode = cpNode;
        this.integerNode = integerNode;
    }
    childNodes() {
        return flatten(this.cpNode, this.integerNode);
    }
    debug() {
        var _a, _b;
        return `(${this.type}${[
            (_a = this.integerNode) === null || _a === void 0 ? void 0 : _a.debug(),
            this.cpType,
            (_b = this.cpNode) === null || _b === void 0 ? void 0 : _b.debug(),
        ]
            .filter((x) => x)
            .map((x) => ` ${x}`)
            .join("")})`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        // TODO: Keep this so we don't have to split it.
        let twoLetterType = this.type.split("-")[1];
        if (twoLetterType === "successes")
            twoLetterType = "";
        return summarize([twoLetterType, this.integerNode, this.cpType, this.cpNode], maxLength);
    }
}
exports.ModifierNode = ModifierNode;
class TableNode extends GenericNode {
    constructor(location, numDice, tableName) {
        super("table", location);
        this.location = location;
        this.numDice = numDice;
        this.tableName = tableName;
    }
    childNodes() {
        return flatten(this.numDice, this.tableName);
    }
    debug() {
        var _a;
        return `(table ${((_a = this.numDice) === null || _a === void 0 ? void 0 : _a.debug()) || ""}t${debug(this.tableName)})`;
    }
    summarize(maxLength = Number.POSITIVE_INFINITY) {
        return summarize([this.numDice, "t[", ...this.tableName, "]"]);
    }
}
exports.TableNode = TableNode;
exports.Mapping = {
    lines: CompoundNode,
    blank: LineNode,
    comment: LineNode,
    chat: LineNode,
    "/roll": LineNode,
    "/gmroll": LineNode,
    "/w": LineNode,
    "/em": LineNode,
    "/ooc": LineNode,
    "/emas": LineNode,
    "/as": LineNode,
    "/desc": LineNode,
    "/fx": LineNode,
    "/talktomyself": LineNode,
    label: LabelNode,
    dice: DiceNode,
    "grouped-dice": GroupedDiceNode,
    fate: FateNode,
    table: TableNode,
    "modifier-successes": ModifierNode,
    "modifier-!": ModifierNode,
    "modifier-!!": ModifierNode,
    "modifier-!p": ModifierNode,
    "modifier-f": ModifierNode,
    "modifier-m": ModifierNode,
    "modifier-mt": ModifierNode,
    "modifier-cs": ModifierNode,
    "modifier-cf": ModifierNode,
    "modifier-r": ModifierNode,
    "modifier-ro": ModifierNode,
    "modifier-s": ModifierNode,
    "modifier-sa": ModifierNode,
    "modifier-sd": ModifierNode,
    "modifier-k": ModifierNode,
    "modifier-kh": ModifierNode,
    "modifier-kl": ModifierNode,
    "modifier-d": ModifierNode,
    "modifier-dh": ModifierNode,
    "modifier-dl": ModifierNode,
    paren: ExprNode,
    "+": ExprNode,
    "-": ExprNode,
    "*": ExprNode,
    "/": ExprNode,
    "%": ExprNode,
    "**": ExprNode,
    abs: ExprNode,
    floor: ExprNode,
    ceil: ExprNode,
    round: ExprNode,
    ability: CompoundNode,
    macro: CompoundNode,
    "attribute-query": CompoundNode,
    "roll-query": CompoundNode,
    "inline-roll": CompoundNode,
    "roll-flag": CompoundNode,
    placeholder: PlaceholderNode,
    "template-row": CompoundNode,
    group: CompoundNode,
    "key-value": KeyValueNode,
    literal: NumericLiteralNode,
    text: TextNode,
    quote: CompoundNode,
    error: CompoundNode,
    "markdown-code": CompoundNode,
    "markdown-italic": CompoundNode,
    "markdown-bold": CompoundNode,
    "markdown-link": MarkdownLinkNode,
    "markdown-html": CompoundNode,
    test: CompoundNode, // for testing only
};
function flatten(...args) {
    const out = [];
    for (const arg of args) {
        if (arg instanceof Array) {
            out.push(...arg);
        }
        else if (arg) {
            out.push(arg);
        }
    }
    return out;
}
/**
 * Summarizes the passed nodes, recreating the input as closely as possible.
 */
function summarize(nodes, maxLength = Number.POSITIVE_INFINITY, joiner = "") {
    const joinerLength = joiner.length;
    const filteredNodes = nodes.filter((x) => x !== undefined);
    if (filteredNodes.length === 0)
        return "";
    else if (filteredNodes.length === 1) {
        const node = filteredNodes[0];
        if (typeof node === "string") {
            return truncate(node, maxLength);
        }
        return node.summarize(maxLength);
    }
    const output = [];
    for (const node of filteredNodes) {
        const string = typeof node === "string" ? node : node.summarize();
        if (maxLength < string.length + joinerLength) {
            // Break out.
            while (maxLength < ELLIPSIS_LENGTH) {
                const popped = output.pop();
                if (popped === undefined)
                    return ""; // can't even fit '…'!
                maxLength += popped.length;
            }
            output.push(ELLIPSIS);
            break;
        }
        else {
            // Decrement and continue.
            output.push(string);
            maxLength -= string.length;
            maxLength -= joinerLength;
        }
    }
    return output.join(joiner);
}
exports.summarize = summarize;
function checkNodeArray(possibleNodeArray) {
    if (!Array.isArray(possibleNodeArray)) {
        throw new Error(`Not an array: ${debug(possibleNodeArray)}`);
    }
    else if (!possibleNodeArray.every((x) => x instanceof GenericNode)) {
        throw new Error(`Non-nodes present: ${debug(possibleNodeArray)}`);
    }
}
function truncate(string, maxLength = Number.POSITIVE_INFINITY) {
    if (string.length > maxLength) {
        if (maxLength < ELLIPSIS_LENGTH)
            return "";
        return `${string.substr(0, maxLength - ELLIPSIS_LENGTH)}${ELLIPSIS}`;
    }
    return string;
}
/** Visits the node and its children in a preorder depth-first traversal. */
function visit(rootNode, visitor) {
    const stack = [[rootNode, []]];
    while (stack.length) {
        let [node, parents] = stack.shift();
        // mess with the stack first
        let toUnshift = node
            .childNodes()
            .map((x) => [x, [...parents, x]]);
        stack.unshift(...toUnshift);
        // then pass the parents array to the visitor, returning early if true
        if (visitor(node, parents))
            return;
    }
}
exports.visit = visit;
function debug(any) {
    if (Array.isArray(any)) {
        return `[${any.map((x) => debug(x)).join(", ")}]`;
    }
    else if (any instanceof GenericNode) {
        return any.debug();
    }
    else {
        return String(any);
    }
}
exports.debug = debug;
function textValue(node) {
    if (node.type === "text")
        return node.value;
}
