"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ace_editor = (0, vue_1.resolveComponent)("ace-editor");
    return (_ctx.useAce)
        ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_ace_editor, {
            key: 0,
            class: (0, vue_1.normalizeClass)(['h-100', ..._ctx.aceTextState]),
            onInit: _ctx.editorInit,
            lang: "text",
            theme: "chrome",
            value: _ctx._value || '',
            ref: "aceInput",
            options: {
                printMargin: false,
                wrap: true,
                newLineMode: 'unix',
                fontSize: '12pt',
            },
            placeholder: "Enter template here, e.g. \"2d20cs>@{hd}sd\" or \"/em laughs.\"",
            "onUpdate:value": _cache[0] || (_cache[0] = ($event) => (_ctx.notifyChange($event)))
        }, null, 8 /* PROPS */, ["class", "onInit", "value"]))
        : (0, vue_1.withDirectives)(((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("textarea", {
            key: 1,
            ref: "input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx._value) = $event)),
            class: (0, vue_1.normalizeClass)([{
                    'is-valid': _ctx.textState === true,
                    'is-invalid': _ctx.textState === false,
                }, "form-control h-100 w-100 textarea-monospace"]),
            onSelect: _cache[2] || (_cache[2] =
                //@ts-ignore
                (...args) => (_ctx.updateCursorPosition && _ctx.updateCursorPosition(...args))),
            onMouseup: _cache[3] || (_cache[3] =
                //@ts-ignore
                (...args) => (_ctx.updateCursorPosition && _ctx.updateCursorPosition(...args))),
            onKeyup: _cache[4] || (_cache[4] =
                //@ts-ignore
                (...args) => (_ctx.updateCursorPosition && _ctx.updateCursorPosition(...args))),
            placeholder: "Enter template here, e.g. \"2d20cs>@{hd}sd\" or \"/em laughs.\"",
            "on:update:modelValue": _cache[5] || (_cache[5] = ($event) => (_ctx.notifyChange($event)))
        }, "\n  ", 34 /* CLASS, HYDRATE_EVENTS */)), [
            [vue_1.vModelText, _ctx._value]
        ]);
}
exports.render = render;
