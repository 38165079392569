"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    props: {
        template: String,
        ranges: { type: Array },
    },
    render() {
        if (!this.template)
            return (0, vue_1.h)("div", null, []);
        if (!this.ranges)
            return (0, vue_1.h)("div", null, this.template);
        const sortedRanges = this.ranges.slice(0);
        sortedRanges.sort(([aStart, aEnd], [bStart, bEnd]) => {
            return aEnd - bEnd;
        });
        let start = 0, childNodes = [];
        for (let [rangeStart, rangeEnd] of sortedRanges) {
            childNodes.push(this.template.slice(start, rangeStart));
            childNodes.push((0, vue_1.h)("mark", null, this.template.slice(rangeStart, rangeEnd)));
            start = rangeEnd;
        }
        childNodes.push(this.template.slice(start));
        return (0, vue_1.h)("div", null, childNodes);
    },
});
