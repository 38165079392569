"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = {
    key: 0,
    class: "bi bi-x-circle-fill text-danger"
};
const _hoisted_2 = {
    key: 1,
    class: "bi bi-exclamation-triangle-fill text-warning"
};
const _hoisted_3 = {
    key: 2,
    class: "bi bi-info-circle-fill text-primary"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_highlight_button = (0, vue_1.resolveComponent)("highlight-button");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("ul", null, [
        ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.annotations, (annotation, $index) => {
            return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", { key: $index }, [
                (annotation.isError())
                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("i", _hoisted_1))
                    : (annotation.isWarning())
                        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("i", _hoisted_2))
                        : (annotation.isInfo())
                            ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("i", _hoisted_3))
                            : (0, vue_1.createCommentVNode)("v-if", true),
                (0, vue_1.createTextVNode)("   " + (0, vue_1.toDisplayString)(annotation.message) + " ", 1 /* TEXT */),
                (_ctx.showHighlightButton)
                    ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_highlight_button, {
                        key: 3,
                        onClick: ($event) => (_ctx.$emit('highlightNode', annotation.source)),
                        variant: annotation.isError()
                            ? 'outline-danger'
                            : annotation.isWarning()
                                ? 'outline-warning'
                                : 'outline-primary'
                    }, null, 8 /* PROPS */, ["onClick", "variant"]))
                    : (0, vue_1.createCommentVNode)("v-if", true)
            ]));
        }), 128 /* KEYED_FRAGMENT */))
    ]));
}
exports.render = render;
