"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "roll-template-table" };
const _hoisted_2 = { colspan: "2" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = {
    key: 1,
    colspan: "2"
};
const _hoisted_5 = { key: 2 };
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_renderer = (0, vue_1.resolveComponent)("renderer");
    const _component_highlight_button = (0, vue_1.resolveComponent)("highlight-button");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("table", _hoisted_1, [
        (0, vue_1.createElementVNode)("thead", null, [
            (0, vue_1.createElementVNode)("tr", null, [
                (0, vue_1.createElementVNode)("th", _hoisted_2, [
                    (0, vue_1.createTextVNode)(" Template: "),
                    (_ctx.templateName.length)
                        ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_renderer, {
                            key: 0,
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click', $event))),
                            nodes: _ctx.templateName
                        }, null, 8 /* PROPS */, ["nodes"]))
                        : ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: 1 }, [
                            (0, vue_1.createTextVNode)("unspecified")
                        ], 64 /* STABLE_FRAGMENT */))
                ]),
                (_ctx.showHighlightButton)
                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("th", _hoisted_3, [
                        (_ctx.templateNameRollFlag)
                            ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_highlight_button, {
                                key: 0,
                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('click', _ctx.templateNameRollFlag)))
                            }))
                            : (0, vue_1.createCommentVNode)("v-if", true)
                    ]))
                    : (0, vue_1.createCommentVNode)("v-if", true)
            ])
        ]),
        (0, vue_1.createElementVNode)("tbody", null, [
            ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.templateRows, (templateRow, index) => {
                return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("tr", { key: index }, [
                    (_ctx.asKeyValue(templateRow))
                        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, { key: 0 }, [
                            (0, vue_1.createElementVNode)("th", null, [
                                (0, vue_1.createVNode)(_component_renderer, {
                                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('click', $event))),
                                    nodes: _ctx.asKeyValue(templateRow).key
                                }, null, 8 /* PROPS */, ["nodes"])
                            ]),
                            (0, vue_1.createElementVNode)("td", null, [
                                (0, vue_1.createVNode)(_component_renderer, {
                                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('click', $event))),
                                    nodes: _ctx.asKeyValue(templateRow).value
                                }, null, 8 /* PROPS */, ["nodes"])
                            ])
                        ], 64 /* STABLE_FRAGMENT */))
                        : ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("td", _hoisted_4, [
                            (0, vue_1.createVNode)(_component_renderer, {
                                onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('click', $event))),
                                nodes: templateRow.children
                            }, null, 8 /* PROPS */, ["nodes"])
                        ])),
                    (_ctx.showHighlightButton)
                        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("td", _hoisted_5, [
                            (0, vue_1.createVNode)(_component_highlight_button, {
                                onClick: ($event) => (_ctx.$emit('click', templateRow))
                            }, null, 8 /* PROPS */, ["onClick"])
                        ]))
                        : (0, vue_1.createCommentVNode)("v-if", true)
                ]));
            }), 128 /* KEYED_FRAGMENT */))
        ])
    ]));
}
exports.render = render;
