"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "container-fluid vh-100 d-flex flex-column" };
const _hoisted_2 = {
    id: "main-container",
    class: "container-fluid"
};
const _hoisted_3 = {
    id: "editor-holder",
    class: "container-fluid"
};
const _hoisted_4 = {
    id: "main-cards",
    class: "d-flex flex-column"
};
const _hoisted_5 = {
    id: "main-card",
    class: "card flex-shrink-1"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_nav_bar = (0, vue_1.resolveComponent)("nav-bar");
    const _component_editor = (0, vue_1.resolveComponent)("editor");
    const _component_explore_pane = (0, vue_1.resolveComponent)("explore-pane");
    const _component_tab = (0, vue_1.resolveComponent)("tab");
    const _component_environment_pane = (0, vue_1.resolveComponent)("environment-pane");
    const _component_simulate_pane = (0, vue_1.resolveComponent)("simulate-pane");
    const _component_debug_pane = (0, vue_1.resolveComponent)("debug-pane");
    const _component_tabs = (0, vue_1.resolveComponent)("tabs");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [
        (0, vue_1.createVNode)(_component_nav_bar),
        (0, vue_1.createElementVNode)("div", _hoisted_2, [
            (0, vue_1.createElementVNode)("div", _hoisted_3, [
                (0, vue_1.createVNode)(_component_editor, {
                    ref: "editor",
                    modelValue: _ctx.template,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.template) = $event)),
                    useAce: _ctx.settings.useAce,
                    textState: (_a = _ctx.textState) !== null && _a !== void 0 ? _a : undefined,
                    onCursorChange: _ctx.cursorMoved
                }, null, 8 /* PROPS */, ["modelValue", "useAce", "textState", "onCursorChange"])
            ]),
            (0, vue_1.createElementVNode)("div", _hoisted_4, [
                (0, vue_1.createElementVNode)("div", _hoisted_5, [
                    (0, vue_1.createVNode)(_component_tabs, null, {
                        default: (0, vue_1.withCtx)(() => [
                            (0, vue_1.createVNode)(_component_tab, { title: "Explore" }, {
                                default: (0, vue_1.withCtx)(() => [
                                    (0, vue_1.createVNode)(_component_explore_pane, {
                                        onHighlightError: _ctx.highlightError,
                                        onHighlightNode: _ctx.highlightNode
                                    }, null, 8 /* PROPS */, ["onHighlightError", "onHighlightNode"])
                                ]),
                                _: 1 /* STABLE */
                            }),
                            (0, vue_1.createVNode)(_component_tab, { title: "Set values" }, {
                                default: (0, vue_1.withCtx)(() => [
                                    (0, vue_1.createVNode)(_component_environment_pane)
                                ]),
                                _: 1 /* STABLE */
                            }),
                            (0, vue_1.createVNode)(_component_tab, { title: "Simulate" }, {
                                default: (0, vue_1.withCtx)(() => [
                                    (0, vue_1.createVNode)(_component_simulate_pane)
                                ]),
                                _: 1 /* STABLE */
                            }),
                            (_ctx.settings.debug)
                                ? ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_tab, {
                                    key: 0,
                                    title: "Debug"
                                }, {
                                    default: (0, vue_1.withCtx)(() => [
                                        (0, vue_1.createVNode)(_component_debug_pane)
                                    ]),
                                    _: 1 /* STABLE */
                                }))
                                : (0, vue_1.createCommentVNode)("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                    })
                ])
            ])
        ])
    ]));
}
exports.render = render;
