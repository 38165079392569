"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decode = exports.encode = void 0;
const base64_arraybuffer_1 = require("base64-arraybuffer");
const pako_1 = require("pako");
function encode(object) {
    const deflator = new pako_1.Deflate({ level: 9 });
    const rawJson = JSON.stringify(object);
    deflator.push(rawJson, true);
    return (0, base64_arraybuffer_1.encode)(deflator.result.buffer)
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
}
exports.encode = encode;
function decode(encodedData) {
    encodedData = encodedData.replace(/-/g, "+").replace(/_/g, "/");
    const inflator = new pako_1.Inflate({ to: "string" });
    inflator.push((0, base64_arraybuffer_1.decode)(encodedData), true);
    return JSON.parse(inflator.result);
}
exports.decode = decode;
